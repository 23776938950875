import React, { useState } from "react";

import {
  TooltipProvider,
  Tooltip as TooltipRoot,
  TooltipTrigger,
  TooltipPortal,
  TooltipContent,
  TooltipArrow,
} from "@/components/ui/tooltip";

import { cn } from "@/lib/utils";

interface TooltipProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  color?: string;
  content: string | React.ReactNode;
  open?: boolean;
};

export default function Tooltip({
  children,
  content,
  icon,
  color,
  open = false,
}: TooltipProps) {
  const [internatOpen, setInternatOpen] = useState<boolean>(false);

  return (
    <TooltipProvider>
      <TooltipRoot open={internatOpen || open}>
        <TooltipTrigger
          asChild
          onMouseEnter={() => setInternatOpen(true)}
          onMouseLeave={() => setInternatOpen(false)}
        >
          {children}
        </TooltipTrigger>
        <TooltipPortal>
          <TooltipContent
            className="bg-white p-2 shadow-lg rounded"
            sideOffset={5}
          >
            <div className="flex gap-2 items-center text-foreground">
              {icon && (icon)}
              <div className="text-sm">{content}</div>
              {!!color && (
                <div className={cn('w-2 h-2 rounded-full', color)} />
              )}
            </div>
            <TooltipArrow className="TooltipArrow fill-white" />
          </TooltipContent>
        </TooltipPortal>
      </TooltipRoot>
    </TooltipProvider>
  );
};
