import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import { LoaderCircleIcon } from "lucide-react";

import VideoPlayerActions from "@/components/VideosPlayer/components/VideoPlayerActions";
import VideoPlayerControls from "@/components/VideosPlayer/components/VideoPlayerControls";
import VideoTimelapseControl from "@/components/VideosPlayer/components/VideoTimelapseControl";

import { useLoaderContext } from "@/contexts/LoaderContext";
import { cn } from "@/lib/utils";

import { CameraInputOutputDTO } from "@/models/CameraInputOutputDTO";
import { CorteDoVideo } from "@/models/CorteDoVideo";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";
import { VideoSourceType } from "@/models/VideoSourceType";

import "react-datepicker/dist/react-datepicker.css";

interface VideosModalProps {
  mosaicFullscreen: boolean;
  playbackRate: number;
  selectedVideo: VideoInputOutputDTO;
  selectedVideoIndex: number;
  shouldRemount: boolean;
  showPlaylist: boolean;
  veiculoId: string;
  videoFullscreen: boolean;
  videoStart?: number;
  videosList: VideoInputOutputDTO[];
  videosSources: VideoSourceType[];
  visibleControl: boolean;
  handleClickPlaybackRate: () => void;
  handleSetShouldRemount: Dispatch<SetStateAction<boolean>>;
  handleSetVideoFullscreen: Dispatch<SetStateAction<boolean>>;
  handleSetVideosSources: Dispatch<SetStateAction<VideoSourceType[]>>;
  handleSetVisibleControl: Dispatch<SetStateAction<boolean>>;
};

export default function VideosPlayer({
  mosaicFullscreen,
  playbackRate,
  selectedVideo,
  selectedVideoIndex,
  shouldRemount,
  videoFullscreen,
  videoStart = 0,
  videosList,
  videosSources,
  visibleControl,
  handleClickPlaybackRate,
  handleSetShouldRemount,
  handleSetVideoFullscreen,
  handleSetVideosSources,
  handleSetVisibleControl,

}: VideosModalProps) {
  const navigate = useNavigate();

  const { handleStartLoader, handleStopLoader } = useLoaderContext();

  const videoPlayers = [
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null),
    useRef<ReactPlayer | null>(null)
  ];

  const [playing, setPlaying] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [videoGuideKey, setVideoGuideKey] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [durationList, setDurationList] = useState<number[]>([]);
  const [activeVideo, setActiveVideo] = useState<number>(0);
  const [cortesDosVideos, setCortesDosVideos] = useState<CorteDoVideo[]>([]);
  const [bufferEnded, setBufferEnded] = useState<boolean>(false);
  const [videoErrors, setVideoErrors] = useState<string[]>([]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const remount = () => {
      handleSetShouldRemount((prev) => !prev);
    };

    if (!bufferEnded) {
      timer = setTimeout(remount, 1000 * 5);
    }

    return () => { clearTimeout(timer) };
  }, [bufferEnded, handleSetShouldRemount]);

  useEffect(() => {
    if (shouldRemount) {
      handleSetShouldRemount(false);
    }
  }, [shouldRemount, handleSetShouldRemount]);

  const feedReference = (player: ReactPlayer, index: number) => {
    videoPlayers[index].current = player;
  };

  const feedCorteCamera = (
    videoLinks: VideoInputOutputDTO,
    camera: CameraInputOutputDTO,
    cameraNumero: number
  ): CorteDoVideo => ({
      camera: camera,
      inicioDoVideo: '00:00:00',
      fimDoVideo: '00:00:00',
      nomeDoVideo: `camera${
        cameraNumero
      }_${
        videoLinks.veiculoNome
      }_${
        videoLinks.dataDoVideo
      }_${
        videoLinks.horaDoVideo
      }`,
      acoes: 'gravar',
  });

  useEffect(() =>  {
    if (!handleStartLoader) {
      return;
    }
  }, [handleStartLoader]);

  useEffect(() => {
    const currentSources: VideoSourceType[] = [];
    const currentCortes: CorteDoVideo[] = [];

    if (selectedVideo) {
      const updateSources = (camera: CameraInputOutputDTO, cameraNumber: number) => {
        if (camera?.videoLink && camera.bucketIdDoVideoOriginal) {
          currentSources.push({
            videoId: camera.videoId,
            urlStream: camera.videoLink,
            bucketIdDoVideoOriginal: camera.bucketIdDoVideoOriginal,
            timeLapse: camera?.timeLapse || [],
          });

          currentCortes.push(feedCorteCamera(selectedVideo, camera, cameraNumber));
        }
      };

      if (selectedVideo?.camera1) {
        updateSources(selectedVideo?.camera1, 1)
      }

      if (selectedVideo?.camera2) {
        updateSources(selectedVideo?.camera2, 2);
      }

      if (selectedVideo?.camera3) {
        updateSources(selectedVideo?.camera3, 3);
      }

      if (selectedVideo?.camera4) {
        updateSources(selectedVideo?.camera4, 4);
      }

      handleSetVideosSources(currentSources);
      setCortesDosVideos(currentCortes);
    }
  }, [selectedVideo, handleSetVideosSources]);

  useEffect(() => {
    const timer = setTimeout(() => { handleSetVisibleControl(false) }, 1000 * 5);
  
    const handleUserActivity = () => {
      if (!visibleControl) {
        handleSetVisibleControl(true);
        clearTimeout(timer);
        setTimeout(() => { handleSetVisibleControl(false) }, 5000);
      }
    };
  
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
  
    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [visibleControl, handleSetVisibleControl]);

  const beginnerAction = () => { setPlaying(false) }

  const syncVideos = (shouldPlay: boolean) => {
    beginnerAction();

    if (!shouldPlay) {
      setPlaying(shouldPlay);

      return;
    }

    if (!videoPlayers[videoGuideKey]) {
      beginnerAction();

      return;
    }

    const currentTime = videoPlayers[videoGuideKey].current!.getCurrentTime();

    videoPlayers.forEach((player, index) => {
      const videoDuration = player?.current?.getDuration() || 0;

      if (currentTime < videoDuration) {
        player.current?.seekTo(currentTime, 'seconds');
      } else {
        player.current?.seekTo(videoDuration - 0.5, 'seconds');
      }

      if (index >= videoPlayers?.length - 1) {
        setTimeout(function () {
          setPlaying(shouldPlay);
        }, 500);
      }
    });
  };

  const handlePlay = () => {
    setPlaying(false);
    syncVideos(true);
  };

  const handlePause = () => {
    setPlaying(false);
    syncVideos(false);
  };

  const handlePlaybackRateChange = (speed: number) => {
    beginnerAction();

    if (!videoPlayers[0]) {
      syncVideos(false);

      return;
    }

    const currentTime = videoPlayers[0].current!.getCurrentTime();

    videoPlayers[0].current!.seekTo(speed, 'seconds');
    videoPlayers.forEach((player) => {
      player.current?.seekTo(currentTime, 'seconds');
    });

    syncVideos(true);
  };

  const handleSetVideoTime = (value: number) => {
    if (value < 0) {
      value = 0;
    }

    if (value > 0.999999) {
      value = 0.999999;
    }

    setPlayed(value);
    handleSliderSeek(value);
    setPlaying(false);
    handlePause();
  };

  const handleSeek = (seek: number) => {
    setPlaying(false);

    if (!videoPlayers[videoGuideKey]) {
      return;
    }

    const currentTime = videoPlayers[videoGuideKey].current!.getCurrentTime();

    videoPlayers[videoGuideKey]!.current!.seekTo(
      currentTime + (seek * playbackRate),
      'seconds',
    );
    syncVideos(true);
  };

  const handleSliderSeek = (seek: number) => {
    setPlaying(false);

    if (!videoPlayers[videoGuideKey]) {
      return;
    }

    videoPlayers[videoGuideKey]!.current!.seekTo(seek, 'fraction');
    syncVideos(true);
    setPlaying(false);
  };

  const handleOnReady = (index: number) => {
    if (index !== videoGuideKey) {
      return;
    }

    selectedVideo && !videoStart && handleSeek(0);
    syncVideos(false);
  };
  
  useEffect(() => {
    if (
      !!duration &&
      durationList.length === videosSources.length - (videoErrors?.length || 0)
      && !!videoStart
    ) {
      handleSetVideoTime(videoStart / duration);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, durationList, videoErrors, videoStart, videosSources]);

  const findCorteDoVideo = (
    urlVideo: string,
  ): CorteDoVideo | null | undefined => {
    return cortesDosVideos?.filter(c => c.camera.videoLink === urlVideo)[0]
  };

  const findVideoPlayer = (
    urlVideo: string,
  ): React.MutableRefObject<ReactPlayer | null> => {
    return videoPlayers.filter(c => c.current?.props.url === urlVideo)[0]
  };

  return (
    <div className="w-full mt-2 lg:mb-0 text-white">
      <div className="w-full mb-4 grid grid-cols-2 lg:hidden">
        {videosSources.map((source, index) => (
          <div key={source.videoId} className="text-center">
            <button
              className={cn(
                'text-lg',
                activeVideo === index ? 'opacity-100' : 'opacity-50',
              )}
              onClick={() => setActiveVideo(index)}
            >
              CAM {index + 1}
            </button>
          </div>
        ))}
      </div>
      <div className={cn(
        'relative w-full bg-black',
        'lg:grid lg:gap-3 lg:mx-auto',
        videosSources.length > 1 && 'grid-cols-2',
        mosaicFullscreen && ([
          'lg:h-screen lg:pt-8 lg:pb-4 lg:px-16',
          'lg:fixed lg:top-0 lg:left-0 ',
        ]),
      )}>
        {videosSources.map((source, index) => {
          const isActive = activeVideo === index;
          const bufferEnd = bufferEnded;
          const hasError = videoErrors.includes(source.urlStream);

          return (
            <div
              key={source.videoId}
              className={cn(
                'max-h-[40vh]',
                'lg:w-fit lg:max-w-full lg:mx-auto',
                'lg:relative lg:top-auto lg:left-auto lg:right-auto',
                index > 0 && 'absolute top-0 left-8 right-8',
                isActive ? 'z-20' : 'z-10',
                isActive && videoFullscreen && 'max-h-[100vh]',
                mosaicFullscreen
                  && videosSources.length - videoErrors.length < 3
                  && 'self-center'
              )}
            >
              <div
                className={cn(
                  'w-fit max-h-[40vh] aspect-video mx-auto p-1 relative',
                  'ring-4 ring-transparent',
                  'lg:transition-all',
                  bufferEnd
                    && isActive
                    && !videoFullscreen
                    && !mosaicFullscreen
                    && 'ring-white rounded-xs',
                  bufferEnd && isActive && videoFullscreen && (
                    'w-full h-screen p-0 fixed top-0 left-0 z-30 bg-black'
                  ),
                  isActive && videoFullscreen
                    ? 'min-w-full h-screen max-h-[100vh]'
                    : videosSources.length < 3
                    ? 'lg:max-h-[36vh]'
                    : mosaicFullscreen
                    ? 'lg:max-h-[38vh]'
                    : 'lg:max-h-[22vh]',
                )}
              >
                <button
                  className={cn(
                    'block mx-auto',
                    !bufferEnd && 'h-0 overflow-hidden',
                    isActive && videoFullscreen
                      ? 'fullscreen max-h-[100vh] w-full'
                      : 'max-h-[38vh]',
                    mosaicFullscreen && 'lg:max-h-[40vh]',
                  )}
                  onClick={() => setActiveVideo(index)}
                >
                  {!hasError && (
                    <ReactPlayer
                      ref={(player) => feedReference(player!, index)}
                      playsInline
                      style={{}}
                      height={activeVideo === index && videoFullscreen ? '100vh' : 'auto'}
                      width={activeVideo === index && videoFullscreen ? 'auto' : '100%'}
                      url={source.urlStream}
                      playing={playing}
                      playbackRate={playbackRate}
                      muted={true}
                      onPlay={() => handlePlay}
                      onPause={() => handlePause}
                      onPlaybackRateChange={handlePlaybackRateChange}
                      onDuration={(d: number) => {
                        setDurationList((previous) => [...previous, d]);

                        if (d > duration) {
                          setDuration(d);
                          setVideoGuideKey(index);
                        }
                      }}
                      onProgress={(p) => {
                        if (index === videoGuideKey) {
                          setPlayed(p.played)
                        }
                      }}
                      onError={(error) => {
                        if (!!error?.length) {
                          setVideoErrors((previous) => [...previous, source.urlStream])
                        }
                      }}
                      onEnded={() => {
                        if (
                          index === videoGuideKey &&
                          selectedVideoIndex < videosList.length
                        ) {
                          navigate(`/veiculos/${
                            videosList[selectedVideoIndex].veiculoId
                          }/${
                            videosList[selectedVideoIndex].dataDoVideo
                          }/videos/${
                            videosList[selectedVideoIndex].horaDoVideo.replace(':', '-')
                          }`);
                        }
                      }}
                      onReady={() => handleOnReady(index)}
                      onBufferEnd={() => {
                        if (index === videoGuideKey) {
                          handleStopLoader();
                          setBufferEnded(true);
                        }
                      }}
                      config={{ file: { forceHLS: true } }}
                    />
                  )}
                </button>
                {!bufferEnd && (
                  <div className={cn(
                    'w-full aspect-video flex justify-center items-center',
                    'bg-neutral-600 text-white'
                  )}>
                    <LoaderCircleIcon className="animate-spin" size={64} />
                  </div>
                )}
              </div>
              <div
                className={cn(
                  'hidden',
                  'lg:flex justify-end gap-6 pr-1 mt-4',
                  activeVideo === index && 'flex',
                  activeVideo === index && videoFullscreen && ([
                    'w-fit py-2 justify-center',
                    'fixed top-0 left-1/2 z-40 -translate-x-1/2',
                    'bg-black/50',
                  ]),
                  videoFullscreen && visibleControl && 'opacity-100',
                  videoFullscreen && !visibleControl && 'opacity-0',
                  mosaicFullscreen && 'mt-2'
                )}
              >
                {!hasError && (
                  <VideoPlayerActions
                    videoIndex={index}
                    activeVideo={activeVideo}
                    videoFullscreen={videoFullscreen}
                    videoSource={source}
                    cortesDosVideos={cortesDosVideos}
                    handlePlay={handlePlay}
                    handlePause={handlePause}
                    handleVideoFullscreen={() => {
                      handleSetVideoFullscreen((previous) => !previous);
                    }}
                    findCorteDoVideo={findCorteDoVideo}
                    findVideoPlayer={findVideoPlayer}
                    handleSetCortesDosVideos={setCortesDosVideos}
                    handleSetActiveVideo={() => setActiveVideo(index)}
                  />
                )}
              </div>
            </div>
          );
        })}
        <div className={cn(
          'mt-6 lg:mt-0',
          videoFullscreen && visibleControl && 'opacity-100',
          videoFullscreen && !visibleControl && 'opacity-0',
          videosSources.length > 1 && 'lg:col-span-2',
          mosaicFullscreen && 'lg:hidden',
        )}>
          <VideoTimelapseControl
            played={played}
            duration={duration}
            videoFullscreen={videoFullscreen}
            activeVideo={activeVideo}
            timeLapse={videosSources?.[activeVideo]?.timeLapse || []}
            handleSliderSeek={handleSliderSeek}
          />
        </div>
        <div
          className={cn(
            'flex justify-between items-center gap- px-5',
            'lg:px-0 ',
            videoFullscreen && 'fixed w-1/2 bottom-24 lg:bottom-32 left-1/2 -translate-x-1/2 justify-center bg-black/50 z-40',
            videoFullscreen && visibleControl && 'opacity-100',
            videoFullscreen && !visibleControl && 'opacity-0',
            videosSources.length > 1 && 'lg:col-span-2',
            mosaicFullscreen && 'h-fit'
          )}
        >
          <VideoPlayerControls
            selectedVideo={selectedVideo}
            played={played}
            duration={duration}
            playing={playing}
            playbackRate={playbackRate}
            handleSetPlayed={setPlayed}
            handleSliderSeek={handleSliderSeek}
            handleSetPlaying={setPlaying}
            handleSeek={handleSeek}
            handleSetVideoTime={handleSetVideoTime}
            handleClickPlaybackRate={handleClickPlaybackRate}
          />
        </div>
      </div>
    </div>
  );
};
