import { formatAlertaAiToString } from "@/utils/formatAlertaAiToString";

import { AlertAiType } from "@/models/AlertAiType";

export function formatAlertsTextList(alerts: AlertAiType[]): string[] {
  const counts = {} as any;

  alerts.forEach((alert) => {
    if (counts[alert.aiModel]) {
      counts[alert.aiModel] += 1;
    } else {
      counts[alert.aiModel] = 1;
    }
  });

  const formatAiModel = (model: string) => formatAlertaAiToString(model);

  const result: string[] = [];

  for (let aiModel in counts) {
    const formattedAiModel = formatAiModel(aiModel);

    result.push(`${formattedAiModel} (${counts[aiModel]})`);
  }

  return result;
};
