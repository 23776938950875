import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

import { formatBRLNumber } from '@/utils/formatBRLNumber';

interface SingleValueCardProps {
  title?: string;
  description?: string;
  value: number;
  unit?: string;
  label?: string;
  link?: {
    href: string;
    text: string;
  };
};

export default function SingleValueCard({
  title = '',
  description = '',
  value,
  unit = '',
  label = '',
  link,
}: SingleValueCardProps) {
  return (
    <Card className="w-full h-full flex flex-col justify-between">
      <CardHeader className="items-center pb-0">
        {!!title.length && (
          <CardTitle>{title}</CardTitle>
        )}
        {!!description.length && (
          <CardDescription>{description}</CardDescription>
        )}
      </CardHeader>
      <CardContent className="flex-1 pb-0 my-1 max-h-[192px] h-full flex flex-col justify-center items-center">
        <p className="fill-foreground text-3xl font-bold">
          {formatBRLNumber(value)}
        </p>
        {!!unit && (
          <p className="text-xs text-muted-foreground">{unit}</p>
        )}
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        {!!label.length && (
          <p className="text-muted-foreground">{label}</p>
        )}
        {!!link?.text && !!link?.href && (
          <a
            className="flex items-center gap-2 font-medium leading-none hover:underline"
            href={link.href}
          > 
            {link.text}
          </a>
        )}
      </CardFooter>
    </Card>
  );
};
