import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TriangleAlertIcon, VideoIcon } from "lucide-react";

import FilterPeriodDatePicker from "@/components/FilterPeriodDatePicker";
import NavBar from "@/components/NavBar";
import Tooltip from "@/components/Tooltip";
import VehicleDetailsCard from "@/components/VehicleDetailsCard";
import VideosTable from "@/components/VideosTable";
import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { useFilterContext } from "@/contexts/FilterContext";
import { useLoaderContext } from "@/contexts/LoaderContext";
import { getAllLiveFleet } from "@/services/lives";
import { useFilterVehiclesByToday } from "@/services/hooks/useFilterVehicles";
import { useListVideos } from "@/services/hooks/useVideos";
import { useToast } from "@/hooks/use-toast";
import { cn } from "@/lib/utils";
import { formatAlertaAiToString } from "@/utils/formatAlertaAiToString";

import { OnlineVehicleType } from "@/models/OnlineVehicleType";
import { TVehiclesCamerasByDate } from "@/models/TVehiclesCamerasByDate";

export default function Vehicle() {
  const navigate = useNavigate();

  const { toast } = useToast();

  const { veiculoId = '' } = useParams();

  const previousHasOnlineDevice = useRef<boolean | null>(null);

  const {
    base,
    filterDateRange,
    filterPeriod,
    setDisplayDateRange,
    setFilterDateRange,
    setFilterPeriod,
  } = useFilterContext();
  const { handleStartLoader, handleStopLoader } = useLoaderContext();

  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [onlineVehicles, setOnlineVehicles] = useState<OnlineVehicleType[]>([]);
  const [storedLocalidade, setStoredLocalidade] = useState<string | null>(null);
  const [storedVeiculoNome, setStoredVeiculoNome] = useState<string | null>(null);
  const [videos, setVideos] = useState<TVehiclesCamerasByDate[]>([]);
  const [selectedAlerts, setSelectedAlerts] = useState<string[]>([]);

  const {
    data: vehicleVideos = [],
    isLoading: isLoadingListVideos,
  } = useListVideos(
    veiculoId,
    (filterDateRange?.from || new Date()).toISOString().split('T')[0],
    filterDateRange?.to ? filterDateRange.to.toISOString().split('T')[0] : undefined,
  );

  const { data: vehiclesByToday = [] } = useFilterVehiclesByToday(base);

  const vehicleData = useMemo(() => {
    const data = vehiclesByToday.find((v) => v.veiculoId === veiculoId);

    return data ?? null;
  }, [vehiclesByToday, veiculoId]);

  useEffect(() => {
    setIsMounted(true);

    const fetchFleetData = async () => {
      const data = await getAllLiveFleet();
      if (isMounted) {
        setOnlineVehicles(data);
      }
    };

    if (process.env.REACT_APP_VERIFY_LIVE_VIDEOS === 'true') {
      fetchFleetData();
      
      const interval = setInterval(fetchFleetData, 1000 * 30);

      return () => {
        setIsMounted(false);
        clearInterval(interval);
      };
    }
  }, [isMounted]);

  useEffect(() => {
    if (!handleStartLoader || !handleStopLoader) {
      return;
    }

    if (isLoadingListVideos) {
      handleStartLoader();
    } else {
      const timeout = setTimeout(() => handleStopLoader(), 500);

      return () => clearTimeout(timeout);
    }
  }, [handleStartLoader, handleStopLoader, isLoadingListVideos]);

  useEffect(() => {
    if (
      !!vehicleData &&
      !!vehicleData?.nome &&
      !!vehicleData?.localidade &&
      !storedVeiculoNome &&
      !storedLocalidade
    ) {
      setStoredVeiculoNome(vehicleData.nome);
      setStoredLocalidade(vehicleData.localidade || '');
    }
  }, [vehicleData, storedVeiculoNome, storedLocalidade]);

  useEffect(() => {
    if (!vehicleVideos?.length) {
      return;
    }

    const getVideos = async () => {
      const startObject = filterDateRange?.from || new Date();
      const finalObject = filterDateRange?.to || startObject;
      const formattedStart = startObject.toISOString().split('T')[0];
      const formattedFinal = finalObject.toISOString().split('T')[0];

      if (
        !startObject ||
        !finalObject ||
        !formattedStart?.length ||
        !formattedFinal?.length
      ) {
        return;
      }

      setVideos([]);

      for (
        let date = new Date(startObject);
        date <= finalObject;
        date.setDate(date.getDate() + 1)
      ) {
        const formattedDate = date.toISOString().split('T')[0];
        const vehicleCamera = vehicleVideos.filter(
          (f) => f.dataDoVideo === formattedDate,
        );

        if (!vehicleCamera || vehicleCamera.length === 0) {
          continue;
        }

        if (isMounted) {
          setVideos((previous) => {
            const dateIndex = previous.findIndex(
              (element) => element.date === formattedDate,
            );

            if (dateIndex === -1) {
              return ([
                ...previous,
                {
                  date: formattedDate,
                  vehicles: vehicleCamera || [],
                },
              ]);
            }

            return previous.map((element) => element.date === formattedDate
              ? {
                date: formattedDate,
                vehicles: vehicleCamera || [],
              }
              : element
            );
          });
        }
      }
    };

    getVideos();
  }, [filterDateRange, isMounted, vehicleVideos]);

  useEffect(() => {
    setVideos([]);

    const today = new Date();

    switch (filterPeriod) {
      case 'today':
        setFilterDateRange({ from: today })
        return;

      case 'yesterday':
        const yesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        setFilterDateRange({ from: yesterday })
        return;

      case 'lastWeek':
        const lastWeek = new Date(today);

        lastWeek.setDate(today.getDate() - 7);
        setFilterDateRange({ from: lastWeek, to: today });
        return;

      case 'lastMonth':
        const lastMonth = new Date(today);

        lastMonth.setDate(today.getDate() - 30);
        setFilterDateRange({ from: lastMonth, to: today });
        return;
    }
  }, [filterPeriod, setFilterDateRange]);

  const deviceSerial = useMemo(() => {
    if (!storedVeiculoNome?.length) return '';

    const match = storedVeiculoNome.match(/\((.*?)\)$/);

    return match ? match[1] : '';
  }, [storedVeiculoNome]);

  const hasOnlineDevice = useMemo(() => onlineVehicles?.some(
    (device) => (
      device?.veiculoId === veiculoId &&
      device?.deviceSerial === deviceSerial
    )
  ), [deviceSerial, onlineVehicles, veiculoId]);

  useEffect(() => {
    if (
      !!toast &&
      previousHasOnlineDevice.current === true &&
      hasOnlineDevice === false
    ) {
      toast({
        variant: 'destructive',
        description: "O veículo está offline no momento.",
        duration: 10000,
      });
    }

    previousHasOnlineDevice.current = hasOnlineDevice;
  }, [hasOnlineDevice, toast]);

  const onClickTabTrigger = (period: string) => {
    const today = new Date();

    switch (period) {
      case 'today':
        setDisplayDateRange({ from: today })
        setFilterPeriod('today')
        return;

      case 'yesterday':
        const yesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        setDisplayDateRange({ from: yesterday })
        setFilterPeriod('yesterday')
        return;

      case 'lastWeek':
        const lastWeek = new Date(today);

        lastWeek.setDate(today.getDate() - 7);
        setDisplayDateRange({ from: lastWeek, to: today })
        setFilterPeriod('lastWeek')
        return;

      case 'lastMonth':
        const lastMonth = new Date(today);

        lastMonth.setDate(today.getDate() - 30);
        setDisplayDateRange({ from: lastMonth, to: today })
        setFilterPeriod('lastMonth')
        return;
    }
  };

  const alertList = useMemo(() => {
    const current: string[] = [];

    videos?.forEach((vi) => {
      vi.vehicles?.forEach((ve) => {

        if (ve?.camera1?.alertasAi?.length) {
          ve.camera1.alertasAi.forEach((a) => {
            if (!current.includes(a.aiModel)) current.push(a.aiModel);
          })
        }

        if (ve?.camera2?.alertasAi?.length) {
          ve.camera2.alertasAi.forEach((a) => {
            if (!current.includes(a.aiModel)) current.push(a.aiModel);
          })
        }

        if (ve?.camera3?.alertasAi?.length) {
          ve.camera3.alertasAi.forEach((a) => {
            if (!current.includes(a.aiModel)) current.push(a.aiModel);
          })
        }

        if (ve?.camera4?.alertasAi?.length) {
          ve.camera4.alertasAi.forEach((a) => {
            if (!current.includes(a.aiModel)) current.push(a.aiModel);
          })
        }
      })
    })
    
    return current;
  }, [videos]);

  return (
    <>
      <div className="w-full max-w-[1440px] mx-auto">
        <NavBar bases={[]} />
        <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
          <div>
            <div className="mb-2">
              <Tabs value={filterPeriod}>
                <div className="w-fit flex items-center gap-3 flex-wrap">
                  <TabsList>
                    <TabsTrigger
                      value="today"
                      onClick={() => onClickTabTrigger('today')}
                    >
                      Hoje
                    </TabsTrigger>
                    <TabsTrigger
                      value="yesterday"
                      onClick={() => onClickTabTrigger('yesterday')}
                    >
                      Ontem
                    </TabsTrigger>
                    <TabsTrigger
                      value="lastWeek"
                      onClick={() => onClickTabTrigger('lastWeek')}
                    >
                      Última semana
                    </TabsTrigger>
                    <TabsTrigger
                      className="hidden sm:flex"
                      value="lastMonth"
                      onClick={() => onClickTabTrigger('lastMonth')}
                    >
                      Último mês
                    </TabsTrigger>
                  </TabsList>
                  <FilterPeriodDatePicker />
                  <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button
                      className="text-muted-foreground hover:text-foreground"
                      variant="secondary"
                      disabled={!alertList?.length}
                    >
                      <TriangleAlertIcon className="mr-2 h-4 w-4" />
                      Filtrar por alertas
                      {selectedAlerts?.length ? ` (${selectedAlerts.length})` : ''}
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-56">
                    <DropdownMenuLabel>Alertas</DropdownMenuLabel>
                    <DropdownMenuSeparator />
                    {alertList.map((item) => (
                      <DropdownMenuCheckboxItem
                        key={item}
                        checked={selectedAlerts.includes(item)}
                        onCheckedChange={(checked) => {
                          setSelectedAlerts((previous) => {
                            if (checked) {
                              return [...previous, item];
                            }
                            
                            return previous.filter((alert) => alert !== item);
                          });
                        }}
                      >
                        {formatAlertaAiToString(item)}
                      </DropdownMenuCheckboxItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
                </div>
              </Tabs>
            </div>
            <Card x-chunk="dashboard-06-chunk-0">
              <CardHeader>
                <CardTitle>
                  Veículo: {storedVeiculoNome}
                  {!!veiculoId?.length &&
                  !!deviceSerial?.length &&
                  hasOnlineDevice ? (
                    <Tooltip
                      content="Ao vivo"
                      icon={<VideoIcon size="18" />}
                      color="bg-red-400"
                    >
                      <button
                        className={cn(
                          'ml-3 gap-1.5 inline-flex items-center relative z-30',
                          'text-xs uppercase font-semibold tracking-widest',
                        )}
                        onClick={(event) => {
                          event.stopPropagation();
                          
                          navigate(`/veiculos/${veiculoId}/${deviceSerial}`);
                        }}
                      >
                        <span className="relative flex h-3 w-3">
                          <span className={cn(
                            'w-full h-full absolute inline-flex',
                            'animate-ping rounded-full bg-red-600 opacity-80',
                          )} />
                          <span className={cn(
                            'w-3 h-3 relative inline-flex rounded-full',
                            'bg-red-500',
                          )} />
                        </span>
                      </button>
                    </Tooltip>
                  ) : null}
                </CardTitle>
                <CardDescription>Base: {storedLocalidade}</CardDescription>
              </CardHeader>
              <CardContent>
                <VehicleDetailsCard
                  videos={vehicleVideos}
                  selectedAlerts={selectedAlerts}
                />
                <VideosTable
                  key={videos?.map((v) => v.date || '')?.join('-')}
                  vehicleVideos={videos}
                  selectedAlerts={selectedAlerts}
                />
              </CardContent>
            </Card>
          </div>
        </main>
      </div>
    </>
  );
};
