import { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

interface LiveMapProps {
  coordinates?: [number, number];
  direction?: number;
  route?: [number, number][]; 
}

export default function LiveMap({ coordinates, direction, route }: LiveMapProps) {
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const mapContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!coordinates || coordinates.length < 2 || !mapContainerRef.current) return;

    const container = mapContainerRef.current;

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const newMap = new mapboxgl.Map({
      container,
      style: 'mapbox://styles/mapbox/dark-v10',
      center: coordinates,
      zoom: 14,
    });

    setMap(newMap);

    return () => newMap.remove();
  }, [coordinates]);

  useEffect(() => {
    if (!map || !mapContainerRef.current) return;

    const container = mapContainerRef.current;

    const resizeMap = () => {
      if (map && container.offsetHeight > 0) {
        map.resize();
      }
    };

    const resizeObserver = new ResizeObserver(resizeMap);
    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
    };
  }, [map]);

  useEffect(() => {
    if (!map || !coordinates || coordinates.length < 2) return;

    const addMarker = () => {
      const markerContainer = document.createElement('div');
      markerContainer.style.cssText = 'position:relative;width:40px;height:40px;';

      const markerElement = document.createElement('div');
      markerElement.style.cssText = `
        background-image:url('/images/navigation-marker.svg');
        background-size:contain;
        width:40px;
        height:40px;
        transform-origin:center;
        ${direction !== undefined ? `transform:rotate(${direction - 180}deg);` : ''}
      `;

      markerContainer.appendChild(markerElement);
      new mapboxgl.Marker(markerContainer).setLngLat(coordinates).addTo(map);
    };

    if (map.isStyleLoaded()) {
      addMarker();
    } else {
      map.on('load', addMarker);
    }
  }, [coordinates, direction, map]);

  useEffect(() => {
    if (!map || !route || route.length < 2) return;

    const geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: route,
        },
        properties: {},
      }],
    };

    const addRouteLayer = () => {
      if (map.getSource('route')) {
        (map.getSource('route') as mapboxgl.GeoJSONSource).setData(geoJsonData);
      } else {
        map.addSource('route', { type: 'geojson', data: geoJsonData });
        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: { 'line-join': 'round', 'line-cap': 'round' },
          paint: { 'line-color': '#2563EB', 'line-width': 4, 'line-opacity': 0.8 },
        });
      }

      const bounds = new mapboxgl.LngLatBounds();
      route.forEach(coord => bounds.extend(coord));
      map.fitBounds(bounds, { padding: 50, maxZoom: 15 });
    };

    if (map.isStyleLoaded()) {
      addRouteLayer();
    } else {
      map.on('load', addRouteLayer);
    }
  }, [route, map]);

  return (
    <div ref={mapContainerRef} className="w-full h-full overflow-hidden" />
  );
}
