import { Dispatch, SetStateAction, useMemo, useState, useEffect } from 'react';
import * as Slider from '@radix-ui/react-slider';
import {
  AlertTriangleIcon,
  CarFrontIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  CigaretteIcon,
  EyeOffIcon,
  GaugeIcon,
  PauseIcon,
  PlayIcon,
  SirenIcon,
  SmartphoneIcon,
  UserRoundXIcon,
} from 'lucide-react';

import Tooltip from '../../../Tooltip';
import { formatVideoDuration } from '../../../../lib/utils';
import { VideoInputOutputDTO } from '../../../../models/VideoInputOutputDTO';

import 'react-datepicker/dist/react-datepicker.css';
import { formatAlertaAiToString } from '@/utils/formatAlertaAiToString';

interface VideosModalProps {
  selectedVideo: VideoInputOutputDTO;
  played: number;
  duration: number;
  playing: boolean;
  playbackRate: number;
  handleSetPlayed: (value: number) => void;
  handleSliderSeek: (value: number) => void;
  handleSetPlaying: Dispatch<SetStateAction<boolean>>;
  handleSeek: (value: number) => void;
  handleSetVideoTime: (value: number) => void;
  handleClickPlaybackRate: () => void;
};

export default function VideoPlayerControls({
  selectedVideo,
  played,
  duration,
  playing,
  playbackRate,
  handleSetPlayed,
  handleSliderSeek,
  handleSetPlaying,
  handleSeek,
  handleSetVideoTime,
  handleClickPlaybackRate,
}: VideosModalProps) {
  const [activeTooltips, setActiveTooltips] = useState<{ [key: number]: boolean }>({});

  const alertasAi = useMemo(() => ([
    ...(selectedVideo?.camera1?.alertasAi || []),
    ...(selectedVideo?.camera2?.alertasAi || []),
    ...(selectedVideo?.camera3?.alertasAi || []),
    ...(selectedVideo?.camera4?.alertasAi || []),
  ]), [selectedVideo]);

  const servicesAi = useMemo(() => ([
    ...(selectedVideo?.camera1?.servicosAi || []),
    ...(selectedVideo?.camera2?.servicosAi || []),
    ...(selectedVideo?.camera3?.servicosAi || []),
    ...(selectedVideo?.camera4?.servicosAi || []),
  ]), [selectedVideo]);

  useEffect(() => {
    alertasAi.forEach(({ startMoment }) => {
      if (
        (played - (startMoment / (duration || 1))) >= 0 &&
        !activeTooltips[startMoment] &&
        typeof activeTooltips[startMoment] === 'undefined'
      ) {
        setActiveTooltips((previous) => ({
          ...previous,
          [startMoment]: true,
        }));
  
        setTimeout(() => {
          setActiveTooltips((previous) => ({
            ...previous,
            [startMoment]: false,
          }));
        }, 1000 * 3);
      }
    });
  }, [played, alertasAi, activeTooltips, duration]);

  const onValueChange = (value: number[]) => handleSetPlayed(value[0]);

  const onValueCommit = (value: number[]) => handleSliderSeek(value[0]);

  const onSwitchPlaying = () => handleSetPlaying((previous) => !previous);

  const onSeekPrevious = () => handleSeek(-10);
  
  const onSeekNext = () => handleSeek(10);

  return (
    <div className="grid grid-cols-12 gap-2 w-full">
      <div className="flex justify-center items-center text-sm col-span-2 lg:col-span-1 lg:text-lg">
        {formatVideoDuration(duration * played)}
      </div>
      <div className="ml-auto w-full h-8 flex items-center col-span-8 lg:h-10 lg:col-span-6">
        <Slider.Root
          className="relative select-none w-full"
          value={[played]}
          max={1}
          step={0.001}
          onValueChange={onValueChange}
          onValueCommit={onValueCommit}
        >
          <Slider.Track
            className="block relative flex-grow rounded-full bg-gray-500 w-full h-2"
          >
            <Slider.Range className="absolute bg-white rounded-l-full h-full" />
          </Slider.Track>
          <Slider.Thumb
            className="block relative w-5 h-5 bg-white rounded-full -translate-y-1/2 -top-1 hover:bg-chart-200 focus:outline-none focus:shadow-md"
            aria-label="Tempo do vídeo"
          />
          {!!alertasAi.length && (
            <>
              {alertasAi.map(({ startMoment, aiModel }) => {
                const icon = (() => {
                  switch (aiModel) {
                    case 'TROCA_DE_FAIXA_SEM_SINALIZAÇÃO':
                    case 'TROCA_DE_FAIXA_SEM_SINALIZACAO':
                      return <CarFrontIcon className="inline-block" size="18" />;

                    case 'MOTORISTA COM CIGARRO':
                      return <CigaretteIcon className="inline-block" size="18" />;

                    case 'MASCARAMENTO':
                      return <EyeOffIcon className="inline-block" size="18" />;

                    case 'EXCESSO_DE_VELOCIDADE':
                    case 'LIMITE_DE_VELOCIDADE_EXCEDIDO':
                      return <GaugeIcon className="inline-block" size="18" />;

                    case 'BOTÃO_DE_PÂNICO':
                    case 'BOTAO_DE_PANICO':
                      return <SirenIcon className="inline-block" size="18" />;

                    case 'MOTORISTA_COM_CELULAR':
                      return <SmartphoneIcon className="inline-block" size="18" />;

                    case 'MOTORISTA_BOCEJANDO':
                    case 'MOTORISTA_DISTRAÍDO':
                    case 'MOTORISTA_DISTRAIDO':
                    case 'MOTORISTA_EM_FADIGA':
                      return <UserRoundXIcon className="inline-block" size="18" />;

                    default:
                      return <AlertTriangleIcon className="inline-block" size="18" />;
                  }
                })();

                return (
                  <Tooltip
                    key={`${startMoment}-${aiModel}`}
                    open={activeTooltips[startMoment] || false}
                    content={(
                      <div className="space-y-2 max-w-md">
                        <p className="flex gap-x-2 items-center">
                          {icon}
                          <span>
                            {formatAlertaAiToString(aiModel)}
                          </span>
                          <div
                            className="inline-block w-2 h-2 rounded-full bg-amber-400"
                          />
                        </p>
                      </div>
                    )}
                  >
                    <button
                      className="absolute top-0 w-4 h-4 rounded-full bg-amber-400 hover:ring-2 hover:ring-yellow-400/50 transition-colors"
                      style={{
                        left: `${(startMoment / duration) * 100}%`,
                        transform: `translateY(-4px) translateX(calc(-${(startMoment / duration) * 100}%))`,
                      }}
                      onPointerDown={(event) => {
                        event.stopPropagation();
                        handleSetVideoTime(startMoment / duration);
                      }}
                    />
                  </Tooltip>
                );
              })}
            </>
          )}
          {!!servicesAi.length && (
            <>
              {servicesAi.map(({ startMoment, aiModel }) => (
                <Tooltip
                  key={`${startMoment}-${aiModel}`}
                  content="Serviço"
                  color="bg-green-400"
                >
                  <div
                    className="absolute top-0 w-4 h-4 -translate-x-[calc(75%-3px)] -translate-y-1 rounded-full bg-green-400 hover:ring-2 hover:ring-green-400/50 transition-colors cursor-pointer"
                    style={{ left: `${(startMoment / duration) * 100}%`}}
                    onPointerDown={(event) => {
                      event.stopPropagation();
                      handleSetVideoTime(startMoment / duration);
                    }}
                  />
                </Tooltip>
              ))}
            </>
          )}
        </Slider.Root>
      </div>
      <div className="flex justify-center items-center text-sm col-span-2 lg:col-span-1 lg:text-lg">
        {formatVideoDuration(duration)}
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={onSwitchPlaying}
        >
          {!playing ? (
            <PlayIcon className="block mx-auto" size={36} />
          ) : (
            <PauseIcon className="block mx-auto" size={36} />
          )}
        </button>
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={onSeekPrevious}
        >
          <ChevronsLeftIcon className="block mx-auto" size={36} />
        </button>
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={onSeekNext}
        >
          <ChevronsRightIcon className="block mx-auto" size={36} />
        </button>
      </div>
      <div className="col-span-3 px-2 lg:col-span-1 flex justify-center items-center">
        <button
          className="block w-8 aspect-square mx-auto"
          onClick={handleClickPlaybackRate}
        >
          <span className="text-2xl">{playbackRate}x</span>
        </button>
      </div>
    </div>
  );
};
