import { Link } from "react-router-dom";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ListVideoIcon,
  Maximize2Icon,
  Minimize2Icon,
  XCircleIcon,
  XIcon,
} from "lucide-react";

import VideoDatePicker from "@/components/VideosPlayer/components/VideoDatePicker";

import { cn, formatDateDDMMYY } from "@/lib/utils";

import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";

interface VideoPageHeaderProps {
  activeVideo: number;
  allVideosDates: string[];
  mosaicFullscreen: boolean;
  selectedVideo: VideoInputOutputDTO;
  showPlaylist: boolean;
  veiculoId: string;
  videosList: VideoInputOutputDTO[];
  handleChangeDate: (date: Date) => void;
  handleChangeMosaicFullscreen: () => void;
  handleClickNext: () => void;
  handleClickPrevious: () => void;
  handleTogglePlaylist: () => void;
};

export default function VideoPageHeader({
  activeVideo,
  allVideosDates,
  mosaicFullscreen,
  selectedVideo,
  showPlaylist,
  veiculoId,
  videosList,
  handleChangeDate,
  handleChangeMosaicFullscreen,
  handleClickNext,
  handleClickPrevious,
  handleTogglePlaylist,
}: VideoPageHeaderProps) {
  return (
    <>
      <div className="grid grid-cols-6 gap-y-2 gap-x-4 mb-2 bg-black text-white">
        <div className={cn(
          'col-span-5 -order-2 text-sm',
          'md:-order-3 md:col-span-2 md:row-span-2',
          'lg:text-base',
        )}>
          <p>VEÍCULO: {selectedVideo.veiculoNome}</p>
          <p>DATA: {formatDateDDMMYY(selectedVideo.dataDoVideo)}</p>
          <p>HORA: {selectedVideo.horaDoVideo}</p>
          {!!selectedVideo.localidade?.length ? (
            <p>BASE: {selectedVideo.localidade.replace(/.+ - /g, '')}</p>
          ) : null}
        </div>
        <div className="col-span-6 md:col-span-2 md:-order-2">
          <div className="w-full flex justify-center items-center gap-2 text-center">
            <button
              className="disabled:text-white/25 disabled:cursor-not-allowed"
              disabled={activeVideo === 1}
              onClick={handleClickPrevious}
            >
              <ChevronLeftIcon size={24} />
            </button>
            <h2 className="text-xl">
              Gravação: {
                activeVideo
              }/{
                videosList?.length
              }
            </h2>
            <button
              className="disabled:text-white/25 disabled:cursor-not-allowed"
              disabled={activeVideo === videosList?.length}
              onClick={handleClickNext}
            >
              <ChevronRightIcon size={24} />
            </button>
          </div>
          <p className="text-center text-sm">
            {selectedVideo.inicioDoVideo.substring(0, 5)}
            {' – '}
            {selectedVideo.fimDoVideo.substring(0, 5)}
          </p>
        </div>
        <div className="-order-1 flex justify-end md:col-span-2 space-x-4">
          <button
            className={cn(
              'w-fit text-neutral-500 hover:text-white transition-colors',
              mosaicFullscreen && 'fixed top-4 right-8 z-10',
            )}
            onClick={handleChangeMosaicFullscreen}
          >
            {mosaicFullscreen ? (
              <Minimize2Icon size={32} />
            ) : (
              <Maximize2Icon size={32} />
            )}
          </button>
          <Link
            className="w-fit inline-flex items-center text-neutral-500 hover:text-white transition-colors"
            to={`/veiculos/${veiculoId}`}
          >
            <XCircleIcon size={32} />
          </Link>
        </div>
        <div className="col-span-4 sm:col-span-3 md:col-span-2">
          <div className="w-full max-w-64 mx-auto">
            <VideoDatePicker
              selectedVideo={selectedVideo}
              includeDates={allVideosDates}
              handleChangeDate={handleChangeDate}
            />
          </div>
        </div>
        <div className="w-full col-span-2 sm:col-span-3 md:col-span-2">
          <div className="w-full max-w-64 mx-auto md:mr-0">
            <button
              className={cn(
                'w-full px-3 py-2 flex rounded-2xl border whitespace-nowrap border-white',
              )}
              onClick={handleTogglePlaylist}
            >
              {showPlaylist ? (
                <XIcon className="block mr-2" size={24} />
              ) : (
                <ListVideoIcon className="block mr-2" size={24} />
              )}
              {' '}
              <div className="w-full">
                {showPlaylist ? 'Fechar' : 'Abrir'} Playlist
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
