import { Dispatch, SetStateAction, useEffect, useState } from "react";

import LivePlayer from "@/components/LivePlayer";

import { cn } from "@/lib/utils";

import "react-datepicker/dist/react-datepicker.css";

interface LiveMosaicProps {
  deviceSerial: string;
  videoFullscreen: boolean;
  visibleControl: boolean;
  handleSetVideoFullscreen: Dispatch<SetStateAction<boolean>>;
  handleSetVisibleControl: Dispatch<SetStateAction<boolean>>;
};

export default function LiveMosaic({
  deviceSerial,
  videoFullscreen,
  visibleControl,
  handleSetVideoFullscreen,
  handleSetVisibleControl,
}: LiveMosaicProps) {
  const [activeVideo, setActiveVideo] = useState<number>(1);

  useEffect(() => {
    const timer = setTimeout(() => { handleSetVisibleControl(false) }, 1000 * 5);
  
    const handleUserActivity = () => {
      if (!visibleControl) {
        handleSetVisibleControl(true);
        clearTimeout(timer);
        setTimeout(() => { handleSetVisibleControl(false) }, 5000);
      }
    };
  
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
  
    return () => {
      clearTimeout(timer);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, [handleSetVisibleControl, visibleControl]);

  return (
    <>
      <div className="w-full mt-2 order-1 lg:mt-0 lg:order-2">
        <div className="w-full mb-4 grid grid-cols-4 lg:hidden">
          {Array.from({ length: 4 }, (_, i) => i + 1).map((i) => (
            <div
              key={`source-${i}`}
              className="text-center"
            >
              <button
                className={cn(
                  'text-lg',
                  activeVideo === i ? 'opacity-100' : 'opacity-50',
                )}
                onClick={() => setActiveVideo(i)}
              >
                CAM {i}
              </button>
            </div>
          ))}
        </div>
        <div className={cn(
          'relative w-full px-8',
          'lg:grid lg:gap-4 lg:aspect-auto lg:mx-auto lg:px-0 lg:pl-12',
          'lg:grid-cols-2',
          'lg:mb-6 lg:gap-y-4',
        )}>
          {Array.from({ length: 4 }, (_, i) => i + 1).map((i) => (
            <LivePlayer
              key={i}
              activeVideo={activeVideo}
              deviceSerial={deviceSerial}
              channel={i}
              videoFullscreen={videoFullscreen}
              visibleControl={visibleControl}
              handleSetActiveVideo={setActiveVideo}
              handleSetVideoFullscreen={handleSetVideoFullscreen}
            />
          ))}
        </div>
      </div>
    </>
  );
};
