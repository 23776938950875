import FleetDetails from "@/components/FleetDetails";
import FleetTable from "@/components/FleetTable";
import { Card, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TabsContent } from "@/components/ui/tabs";

import { FilterPeriodType } from "@/models/FilterPeriodType";
import { FilterVehicleType } from "@/models/FilterVehicleType";
import { OnlineVehicleType } from "@/models/OnlineVehicleType";

interface VehiclesTableProps {
  period: FilterPeriodType;
  onlineVehicles: OnlineVehicleType[];
  vehicles: FilterVehicleType[];
};

export default function FleetTabContent({
  period,
  onlineVehicles,
  vehicles,
}: VehiclesTableProps) {
  return (
    <TabsContent value={period}>
      <Card x-chunk="dashboard-06-chunk-0">
        <FleetDetails vehicles={vehicles} />
        <CardContent>
          <ScrollArea
            className="w-full h-auto lg:h-[calc(100vh-464px)] xl:h-[calc(100vh-436px)]"
            type="hover"
          >
            <FleetTable vehicles={vehicles} onlineVehicles={onlineVehicles} />
          </ScrollArea>
        </CardContent>
      </Card>
    </TabsContent>
  );
};
