import { Route, Routes } from "react-router-dom";

import Dashboard from "@/views/Dashboard";
import Fleet from "@/views/Fleet";
import LiveVehicle from "@/views/LiveVehicle";
import Vehicle from "@/views/Vehicle";
import Video2 from "@/views/Video2";

import PrivateRoute from "@/routes/PrivateRoute";
import { useAPIToken } from "@/services/hooks";
import { ROUTES_PATH } from "@/routes/routesPath";

export function Router() {
  useAPIToken();

  return (
    <Routes>
      <Route
        path={ROUTES_PATH.dashboard}
        element={<PrivateRoute element={<Dashboard />} />}
      />
      <Route
        path={ROUTES_PATH.fleet}
        element={<PrivateRoute element={<Fleet />} />}
      />
      <Route
        path={ROUTES_PATH.veiculo}
        element={<PrivateRoute element={<Vehicle />} />}
      />
      <Route
        path={ROUTES_PATH.videos}
        element={<PrivateRoute element={<Video2 />} />}
      />
      <Route
        path={ROUTES_PATH.lives}
        element={<PrivateRoute element={<LiveVehicle />} />}
      />
    </Routes>
  );
};
