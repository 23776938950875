import { useMemo } from 'react';
import {
  Label,
  PolarRadiusAxis,
  RadialBar,
  RadialBarChart,
} from 'recharts';

import SingleValueCard from '../SingleValueCard';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '../ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '../ui/chart';

import { DateConverter } from '../../utils/DateConverter';
import { FilterVehicleType } from '../../models/FilterVehicleType';
import { formatBRLNumber } from '@/utils/formatBRLNumber';

const chartConfig = {
  services: {
    label: 'Serviços',
    color: 'var(--chart-1)',
  },
  travel: {
    label: 'Deslocamento',
    color: 'var(--chart-2)',
  },
} satisfies ChartConfig;

interface ServiceTravelHoursChartProps {
  // link?: {
  //   text: string;
  //   href: string;
  // };
  vehicles: FilterVehicleType[];
  description?: string;
};

export default function ServiceTravelHoursChart({
  // link,
  vehicles,
  description = '',
}: ServiceTravelHoursChartProps) {
  const totalHorasServico = useMemo(() => (
    vehicles.reduce((accumulator, vehicle) => (
      accumulator + DateConverter.ConvertTimeToHourNumber(vehicle?.totalDeHorasEmServico)
    ), 0)
  ), [vehicles]);

  const totalHorasDeslocamento = useMemo(() => (
    vehicles.reduce((accumulator, vehicle) => (
      accumulator + DateConverter.ConvertTimeToHourNumber(vehicle?.totalDeHorasEmDeslocamento)
  ), 0)
  ), [vehicles]);

  const totalHoras = useMemo(() => (
    totalHorasServico + totalHorasDeslocamento
  ), [totalHorasServico, totalHorasDeslocamento]);

  const chartData = useMemo(() => (
    [{ services: totalHorasServico, travel: totalHorasDeslocamento }]
  ), [totalHorasServico, totalHorasDeslocamento]);

  return !!totalHorasDeslocamento ? (
    <Card className="flex flex-col">
      <CardHeader className="items-center pb-0">
        <CardTitle className="w-full text-center">
          Em serviço e deslocamentos
        </CardTitle>
        {!!description && (
          <CardDescription>{description}</CardDescription>
        )}
      </CardHeader>
      <CardContent className="flex flex-1 items-center pb-0 w-full mt-2.5 mb-3.5 max-h-[176px]">
        <ChartContainer
          className="mx-auto aspect-square w-full h-full"
          config={chartConfig}
        >
          <RadialBarChart
            data={chartData}
            startAngle={-45}
            endAngle={225}
            innerRadius={70}
            outerRadius={110}
          >
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
              <Label content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  return (
                    <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
                      <tspan
                        className="fill-foreground text-3xl font-bold"
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 4}
                      >
                        {formatBRLNumber(Math.ceil(totalHoras).toLocaleString())}
                      </tspan>
                      <tspan
                        className="fill-muted-foreground"
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                      >
                        horas
                      </tspan>
                    </text>
                  );
                }
              }} />
            </PolarRadiusAxis>
            <RadialBar
              className="stroke-transparent stroke-2"
              dataKey="services"
              stackId="a"
              cornerRadius={5}
              fill="var(--chart-1)"
            />
            <RadialBar
              className="stroke-transparent stroke-2"
              dataKey="travel"
              fill="var(--chart-2)"
              stackId="a"
              cornerRadius={5}
            />
          </RadialBarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm">
        <div className="grid grid-cols-2 gap-8 text-center">
          <div className="flex flex-col justify-center gap-2 font-medium leading-none">
            <p>
              {`${
                formatBRLNumber(Math.ceil(chartData[0].services)) || 0
              } h (${
                formatBRLNumber(((totalHorasServico / (totalHoras || 1)) || 0) * 100) || 0}
              %)`}
            </p>
            <p className="text-muted-foreground">em serviço</p>
          </div>
          <div className="flex flex-col justify-center gap-2 font-medium leading-none">
            <p>
              {`${
                formatBRLNumber(Math.ceil(chartData[0].travel)) || 0
              } h (${
                formatBRLNumber(((totalHorasDeslocamento / (totalHoras || 1)) || 0) * 100) || 0
              }%)`}
            </p>
            <p className="text-muted-foreground">em deslocamento</p>
          </div>
        </div>
        <a
          className="flex items-center gap-2 font-medium leading-none hover:underline mt-4"
          href="/veiculos"
        >
          ver detalhes
        </a>
      </CardFooter>
    </Card>
  ) : (
    <div className="max-h-[320px]">
      <SingleValueCard
        title="Em serviço"
        description={description}
        value={Math.ceil(totalHoras)}
        unit="horas"
        label="horas em serviço"
        link={{ href: '/veiculos', text: 'ver detalhes' }}
      />
    </div>
  );
};
