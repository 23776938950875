import { useQuery } from 'react-query';

import { http } from '../../useAxios';
import { FilterVehicleType } from '../../../models/FilterVehicleType';

export async function getByToday(filter: string): Promise<FilterVehicleType[]> {
  try {
    const { data: vehiclesByToday } = await http.get<FilterVehicleType[]>(
      `/Videos/ListarVeiculosHoje?filter=${filter}`,
    );

    return vehiclesByToday;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterVehiclesByToday(filter?: string, shouldFetch = true) {
  const ft = filter || 'all';

  return useQuery({
    queryKey: [`vehicles-by-last-today-${ft}`],
    queryFn: () => getByToday(ft),
    staleTime: 1000 * 60 * 60,
    enabled: shouldFetch,
  });
};
