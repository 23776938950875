import { useCallback, useMemo } from "react";
import {
  HardHatIcon,
  MapPinnedIcon,
  TriangleAlertIcon,
  VideoIcon,
} from "lucide-react";

import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import { formatBRLNumber } from "@/utils/formatBRLNumber";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";
import { DateConverter } from "@/utils/DateConverter";
import { AlertAiType } from "@/models/AlertAiType";

interface VehicleDetailsCardProps {
  videos: VideoInputOutputDTO[];
  selectedAlerts?: string[];
};

export default function VehicleDetailsCard({ videos, selectedAlerts = [] }: VehicleDetailsCardProps) {
  const getFilteredAlerts = useCallback((video: VideoInputOutputDTO) => {
    let amount: AlertAiType[] = [];

    if (!!video?.camera1?.alertasAi?.length) {
      amount.push(...video.camera1.alertasAi);
    }

    if (!!video?.camera2?.alertasAi?.length) {
      amount.push(...video.camera2.alertasAi);
    }

    if (!!video?.camera3?.alertasAi?.length) {
      amount.push(...video.camera3.alertasAi);
    }

    if (!!video?.camera4?.alertasAi?.length) {
      amount.push(...video.camera4.alertasAi);
    }

    if (!!selectedAlerts.length) {
      amount = amount.filter((item) => selectedAlerts.includes(item.aiModel))
    }

    return amount;
  }, [selectedAlerts]);

  const totalRecordedHours = useMemo(() => (
    Math.ceil(videos.reduce((acc, v) => {
      const amount = getFilteredAlerts(v);

      return !selectedAlerts.length || (
        selectedAlerts.length &&
        amount?.length
      ) ? (
        acc + DateConverter.ConvertTimeToHourNumber(v.duracaoDoVideo, false)
      ) : acc;
    }, 0))
  ), [videos, selectedAlerts, getFilteredAlerts]);

  const totalServiceHours = useMemo(() => (
    Math.ceil(videos.reduce((acc, v) => {
      const amount = getFilteredAlerts(v);

      return !selectedAlerts.length || (
        selectedAlerts.length &&
        amount?.length
      ) ? (
        acc + DateConverter.ConvertTimeToHourNumber(v.horasEmServico, false)
      ) : acc;
    }, 0))
  ), [videos, selectedAlerts, getFilteredAlerts]);

  const totalTravelHours = useMemo(() => (
    Math.ceil(videos.reduce((acc, v) => {
      const amount = getFilteredAlerts(v);

      return !selectedAlerts.length || (
        selectedAlerts.length &&
        amount?.length
      ) ? (
        acc + DateConverter.ConvertTimeToHourNumber(v.horasEmServico, false)
      ) : acc;
    }, 0))
  ), [videos, selectedAlerts, getFilteredAlerts]);

  const alertCount = useMemo(() => {
    let amount: AlertAiType[] = [];

    videos.forEach((video) => {
      if (!!video?.camera1?.alertasAi?.length) {
        amount.push(...video.camera1.alertasAi);
      }

      if (!!video?.camera2?.alertasAi?.length) {
        amount.push(...video.camera2.alertasAi);
      }

      if (!!video?.camera3?.alertasAi?.length) {
        amount.push(...video.camera3.alertasAi);
      }

      if (!!video?.camera4?.alertasAi?.length) {
        amount.push(...video.camera4.alertasAi);
      }
    });

    if (!!selectedAlerts.length) {
      amount = amount.filter((item) => selectedAlerts.includes(item.aiModel))
    }

    return amount.length;
  }, [videos, selectedAlerts]);

  return (
    <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4 mb-6">
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Horas de gravação</CardTitle>
          <VideoIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{formatBRLNumber(totalRecordedHours) || 0} h</p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Horas em serviço</CardTitle>
          <HardHatIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{formatBRLNumber(totalServiceHours) || 0} h</p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Horas em deslocamento</CardTitle>
          <MapPinnedIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{formatBRLNumber(totalTravelHours) || 0} h</p>
        </CardContent>
      </Card>
      <Card x-chunk="dashboard-01-chunk-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pt-4 pb-1">
          <CardTitle className="text-sm font-medium">Quantidade de alertas</CardTitle>
          <TriangleAlertIcon className="h-5 w-5 text-muted-foreground" />
        </CardHeader>
        <CardContent className="pb-4">
          <p className="text-2xl font-bold">{alertCount || 0}</p>
        </CardContent>
      </Card>
    </div>
  );
};
