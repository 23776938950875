import { useQuery } from 'react-query';

import { http } from '../../useAxios';
import { FilterAlertType } from '../../../models/FilterAlertType';

export async function getAlertsByToday(filter: string): Promise<FilterAlertType[]> {
  try {
    const { data: vehiclesByToday } = await http.get<FilterAlertType[]>(
      `/Videos/ListarAlertasCategorizadosHoje?filter=${filter}`,
    );

    return vehiclesByToday;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterAlertsByToday(filter?: string, shouldFetch: boolean = true) {
  const ft= filter || 'all';

  return useQuery({
    queryKey: [`alerts-by-today-${ft}`],
    queryFn: () => getAlertsByToday(ft),
    staleTime: 1000 * 60 * 60,
    enabled: shouldFetch,
  });
};
