import { http } from "@/services/useAxios";

import { OnlineVehicleType } from "@/models/OnlineVehicleType";
import { VideoLiveType } from "@/models/VideoLiveType";

export const getAllLiveFleet = async () => {
  try {
    const { data: onlineVehicles } = await http.get<OnlineVehicleType[]>(
      '/Videos/ListarVeiculosOnline',
    );

    return onlineVehicles;
  } catch (err) {
    console.log(err);

    return [];
  }
};

export const getVideoLiveByDeviceSerialAndChannel = async (
  deviceSeria: string,
  channel: string | number,
  quality: 'MainStream' | 'SubStream' = 'SubStream',
) => {
  try {
    const { data: liveVideo } = await http.get<VideoLiveType>(
      `/Videos/Live/${deviceSeria}/${channel}/${quality}`,
    );

    return liveVideo;
  } catch (err) {
    console.log(err);

    return null;
  }
};
