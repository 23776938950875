import { useQuery } from 'react-query'
import { DatesRangeValue } from '@mantine/dates';

import { http } from '../../useAxios';
import { FilterAlertType } from '../../../models/FilterAlertType';
import { DateConverter } from '../../../utils/DateConverter';
import { useFilterContext } from '../../../contexts/FilterContext';

export async function getAlertsByRange(
  dateRange: DatesRangeValue,
  filter: string,
): Promise<FilterAlertType[]> {
  try {
    const [from, to] = dateRange;

    if (!from || !to) {
      return [];
    }

    const formattedFrom = DateConverter.ConvertDateToSystemDateFormat(from);
    const formattedTo = DateConverter.ConvertDateToSystemDateFormat(to);
    const { data: vehiclesByToday } = await http.get<FilterAlertType[]>(
      `/Videos/ListarAlertasCategorizadosPorData/${formattedFrom}/${formattedTo}?filter=${filter}`,
    );

    return vehiclesByToday;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterAlertsByRange(
  dateRange: DatesRangeValue,
  filter?: string,
  shouldFetch: boolean = true,
) {
  const { filterDateRange } = useFilterContext();

  let start = '';
  let final = '';

  if (!!dateRange?.[0]) {
    start = dateRange?.[0].toISOString().split('T')[0];
    final = start
  }

  if (!!dateRange?.[1]) {
    final = dateRange?.[1].toISOString().split('T')[0];
  }

  return useQuery(
    `alerts-by-range-${filter || 'all'}-${start}-${final}`,
    () => { return getAlertsByRange(dateRange, filter ?? 'all') },
    {
      staleTime: 1000 * 60 * 60,
      enabled: shouldFetch && !!filterDateRange && !!dateRange,
    },
  );
};
