import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { DateRange } from "react-day-picker";

interface LocalStorageSettingType {
  filterDateRange?: DateRange;
  filterPeriod: string;
};

const getLocalStorageSetting = (): LocalStorageSettingType | null => {
  const settings = localStorage.getItem('DF2RE_SETTINGS');

  if (settings) {
    try {
      const parsedSettings = JSON.parse(settings);

      if (parsedSettings.filterDateRange?.from) {
        parsedSettings.filterDateRange.from = new Date(
          parsedSettings.filterDateRange.from,
        );
      }

      if (parsedSettings.filterDateRange?.to) {
        parsedSettings.filterDateRange.to = new Date(
          parsedSettings.filterDateRange.to,
        );
      }

      return parsedSettings;
    } catch (error) {
      console.error('Erro ao ler do localStorage', error);
    }
  }
  return null;
};

const saveSettingsToLocalStorage = (settings: LocalStorageSettingType) => {
  const settingsToSave = {
    ...settings,
    filterDateRange: {
      from: settings.filterDateRange?.from
        ? settings.filterDateRange.from.toISOString()
        : undefined,
      to: settings.filterDateRange?.to
        ? settings.filterDateRange.to.toISOString()
        : undefined,
    },
  };

  localStorage.setItem('DF2RE_SETTINGS', JSON.stringify(settingsToSave));
};

interface FilterContextType {
  base: string;
  filterDateRange?: DateRange;
  displayDateRange?: DateRange;
  filterPeriod: string;
  setBase: (value: string) => void;
  setFilterDateRange: Dispatch<DateRange | undefined>;
  setDisplayDateRange: Dispatch<DateRange | undefined>;
  setFilterPeriod: (value: string) => void;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

interface FilterProviderProps {
  children: ReactNode;
};

export const FilterProvider = ({ children }: FilterProviderProps) => {
  const savedSettings = getLocalStorageSetting();

  const [base, setBase] = useState<string>('all');
  const [
    filterDateRange,
    setFilterDateRange,
  ] = useState<DateRange | undefined>(savedSettings?.filterDateRange);
  const [
    filterPeriod,
    setFilterPeriod,
  ] = useState<string>(savedSettings?.filterPeriod || 'lastWeek');

  const [
    displayDateRange,
    setDisplayDateRange,
  ] = useState<DateRange | undefined>(() => {
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 6);

    return {
      from: sevenDaysAgo,
      to: today,
    };
  });

  useEffect(() => {
    if (filterDateRange) setDisplayDateRange(filterDateRange);
  }, [filterDateRange]);

  useEffect(() => {
    saveSettingsToLocalStorage({ filterDateRange, filterPeriod });
  }, [filterDateRange, filterPeriod]);

  return (
    <FilterContext.Provider
      value={{
        base,
        filterDateRange,
        displayDateRange,
        filterPeriod,
        setBase,
        setFilterDateRange,
        setDisplayDateRange,
        setFilterPeriod,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = (): FilterContextType => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('Erro ao carregar os filtros.');
  }

  return context;
};
