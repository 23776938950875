import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Hls from "hls.js";
import {
  HdmiPortIcon,
  LoaderCircleIcon,
  MaximizeIcon,
  MinimizeIcon,
  VideoOffIcon,
} from "lucide-react";

import { getVideoLiveByDeviceSerialAndChannel } from "@/services/lives";
import { useToast } from "@/hooks/use-toast";

import { cn } from "@/lib/utils";

interface LivePlayerProps {
  activeVideo: number;
  channel: number;
  deviceSerial: string;
  videoFullscreen: boolean;
  visibleControl: boolean;
  handleSetActiveVideo: Dispatch<SetStateAction<number>>;
  handleSetVideoFullscreen: Dispatch<SetStateAction<boolean>>;
};

export default function LivePlayer({
  activeVideo,
  channel,
  deviceSerial,
  videoFullscreen,
  visibleControl,
  handleSetActiveVideo,
  handleSetVideoFullscreen,
}: LivePlayerProps) {
  const { toast } = useToast();
  
  const playerRef = useRef<HTMLVideoElement | null>(null);

  const [bufferEnded, setBufferEnded] = useState<boolean>(false);
  const [isMainStream, setIsMainStream] = useState<boolean>(false);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [source, setSource] = useState<string>('');

  const isActive = useMemo(() => (
    activeVideo === channel
  ), [activeVideo, channel]);
  
  const quality = useMemo(() => (
    isMainStream ? 'MainStream' : 'SubStream'
  ), [isMainStream]);

  useEffect(() => {
    if (!deviceSerial || !channel) return;
  
    let isMounted = true;
  
    const fetchLive = async () => {
      try {
        const data = await getVideoLiveByDeviceSerialAndChannel(
          deviceSerial,
          channel,
          quality,
        );
  
        if (isMounted && data?.address?.length) {
          setSource((previous) => {
            if (previous !== data.address) {
              return data.address;
            }
  
            if (!!previous?.length) {
              setIsMainStream((prev) => {
                if (
                  (!prev && previous?.includes('-M')) ||
                  (prev && previous?.includes('-S'))
                ) {
                  toast({
                    variant: 'destructive',
                    description: "Erro ao trocar a qualidade do vídeo.",
                  });

                  return !prev;
                }

                return prev;
              });
            }
  
            return previous;
          });
        }
      } catch (error) {
        toast({
          variant: 'destructive',
          description: "Erro ao buscar vídeo.",
        });
      }
    };
  
    fetchLive();
  
    const interval = setInterval(fetchLive, 1000 * 15);
  
    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [channel, deviceSerial, quality, toast]);

  useEffect(() => {
    const video = playerRef.current;

    if (!video || !source) return;

    let hls: Hls | null = null;

    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(source);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, () => video.play().catch(() => {}));
    } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
      video.src = source;
      video.addEventListener("loadedmetadata", () => video.play().catch(() => {}));
    }

    return () => hls?.destroy();
  }, [source]);

  return (
    <>
      <div
        className={cn(
          'w-full mb-12',
          'lg:mb-0 lg:relative lg:top-auto lg:left-auto lg:right-auto lg:mx-auto lg:h-fit',
          isActive ? 'block' : 'hidden lg:block',
        )}
      >
        <div
          className={cn(
            'w-full p-1 aspect-[22/15] max-h-[40vh]',
            'relative flex justify-center items-center',
            'lg:max-h-full',
            'ring-4 ring-transparent',
            bufferEnded
              && isActive
              && videoFullscreen
              && 'h-screen p-0 fixed top-0 left-0 z-30 bg-black',
          )}
        >
          {!!source?.length ? (
            <div
              className={cn(
                'live-player w-full',
                !bufferEnded && 'h-0 overflow-hidden',
                activeVideo === channel && videoFullscreen && 'h-fit w-fit mx-auto'
              )}
            >
              <video
                ref={playerRef}
                className={cn(
                  'w-full max-h-[40vh] lg:max-h-full',
                  isActive && videoFullscreen && 'h-screen'
                )}
                autoPlay
                muted
                controls={false}
                onLoadedData={() => setBufferEnded(true)}
                onWaiting={() => setIsWaiting(true)}
                onPlaying={() => setIsWaiting(false)}
              />
            </div>
          ) : null}
          {!!source?.length && !!bufferEnded && isWaiting ? (
            <div className={cn(
              'w-full h-full aspect-[22/15] absolute top-0 left-0',
              'flex justify-center items-center',
              'bg-black/20 text-white',
            )}>
              <LoaderCircleIcon className="animate-spin" size={64} />
            </div>
          ) : null}
          {!source?.length || !bufferEnded ? (
            <div className={cn(
              'w-full h-full aspect-[22/15] flex justify-center items-center',
              'bg-neutral-950 text-white',
            )}>
              <LoaderCircleIcon className="animate-spin" size={64} />
            </div>
          ) : null}
          {!source?.length && !!bufferEnded ? (
            <div className={cn(
              'min-h-full min-w-full aspect-[22/15]', 
              'flex items-center justify-center',
              'bg-gray-800',
            )}>
              <VideoOffIcon className="text-gray-600" />
            </div>                    
          ) : null}
        </div>
        <div
          className={cn(
            'hidden',
            'lg:flex justify-end gap-6 pr-1 mt-4',
            activeVideo === channel && 'flex',
            activeVideo === channel && videoFullscreen && [
              'w-fit py-2 justify-center fixed top-0 left-1/2 z-40 -translate-x-1/2',
              'bg-black/50'
            ],
            videoFullscreen && visibleControl && 'opacity-100',
            videoFullscreen && !visibleControl && 'opacity-0',
          )}
        >
          <div className="w-full flex justify-end gap-2 lg:gap-6 pr-1">
            <button
              className={cn(
                'w-fit flex items-center gap-2',
                'text-xs uppercase whitespace-nowrap',
                'hover:underline hover:underline-offset-[3px]',
              )}
              onClick={() => setIsMainStream(prev => !prev)}
            >
              <HdmiPortIcon size={16} /> Qualidade: {isMainStream ? 'HD' : 'Normal'}
            </button>
            <button
              className={cn(
                'w-fit flex items-center gap-2',
                'text-xs uppercase whitespace-nowrap',
                'hover:underline hover:underline-offset-[3px]',
              )}
              onClick={() => {
                handleSetActiveVideo(channel);
                handleSetVideoFullscreen(prev => !prev);
              }}
            >
              {!videoFullscreen ? (
                <>
                  <MaximizeIcon size={16} /> Tela cheia
                </>
              ) : (
                <>
                  <MinimizeIcon size={16} /> Minimizar
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
