import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Label, Pie, PieChart } from "recharts"

import SingleValueCard from "@/components/SingleValueCard";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

import { useFilterContext } from "@/contexts/FilterContext";
import { formatBRLNumber } from "@/utils/formatBRLNumber";
import { slugfy } from "@/lib/utils";
import { BaseDTO } from "@/models/BaseDTO";
import { FilterVehicleType } from "@/models/FilterVehicleType";

export type TVehicleBaseChartData = {
  browser: string;
  values: number;
  fill: string;
};

interface VehicleBaseChartProps {
  description?: string;
  vehicles: FilterVehicleType[];
  baseList: BaseDTO[];
};

export default function VehicleBaseChart({
  description,
  vehicles,
  baseList,
}: VehicleBaseChartProps) {
  const navigate = useNavigate();
  const { setBase } = useFilterContext();

  const bases = useMemo(() => (
    vehicles.reduce((accumulator, { localidade }) => {
      if (!!localidade && !accumulator.includes(localidade)) {
        accumulator.push(localidade);
      }

      return accumulator;
    }, [] as string[])
  ), [vehicles]);

  const data = useMemo(() => {
    const current = [] as { base: string, vehicles: FilterVehicleType []}[];

    bases.forEach((base) => {
      current.push({
        base,
        vehicles: vehicles.filter((vehicle) => vehicle.localidade === base)
          .reduce((
            accumulator: FilterVehicleType[],
            vehicle: FilterVehicleType
          ) => {
            if (!accumulator.some((item: FilterVehicleType) => (
              item.nome === vehicle.nome &&
              item.localidade === vehicle.localidade
            ))) {
              accumulator.push(vehicle);
            }

            return accumulator;
        }, [])
      })
    });

    return current.sort((a, b) => b.vehicles.length - a.vehicles.length);
  }, [bases, vehicles]);

  const chartConfig = useMemo(() => {
    const current = {} as { [key: string]: any };

    data.forEach(({ base }, index) => {
      const slug = slugfy(base);

      current[`${slug}`] = {
        label: base,
        color: `var(--chart-${index + 1})`,
      }
    });

    return current as ChartConfig;
  }, [data]);

  const chartData = useMemo(() => {
    const current: TVehicleBaseChartData[] = [];

    data.forEach(({ base, vehicles }) => {
      const slug = slugfy(base);

      current.push({
        browser: slug,
        values: vehicles.length,
        fill: `var(--color-${slug})`,
      });
    });

    return current;
  }, [data]);

  const total = useMemo(() => (
    chartData.reduce((accumulator, current) => accumulator + current.values, 0)
  ), [chartData]);

  const onClick = (slug: string) => {
    if (baseList) {
      const currentBase = baseList?.find(({ chave, nome }) => (
        slugfy(`${chave}-${nome?.toLocaleLowerCase()}`) === slug
      ));

      if (!!currentBase?.chave?.length) {
        setBase(currentBase.chave.toLocaleLowerCase());
        navigate('/veiculos');
      }
    }
  };

  return chartData?.length ? (
    <Card className="flex flex-col justify-between max-h-[320px]">
      <div>
        <CardHeader className="items-center pb-0">
          <CardTitle>Veículos cadastrados</CardTitle>
          {!!description && (
            <CardDescription>{description}</CardDescription>
          )}
        </CardHeader>
        <CardContent className="flex-1 pb-0 my-1 max-h-[192px]">
          <ChartContainer
            config={chartConfig}
            className="mx-auto aspect-square max-h-[192px]"
          >
            <PieChart>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent hideLabel />}
              />
              <Pie
                data={chartData}
                dataKey="values"
                nameKey="browser"
                innerRadius={50}
                outerRadius={70}
                strokeWidth={0}
                onClick={(a, b) => onClick(a.name)}
              >
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={viewBox.cy}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className="fill-foreground text-3xl font-bold"
                          >
                            {formatBRLNumber(total)}
                          </tspan>
                          <tspan
                            className="fill-muted-foreground"
                            x={viewBox.cx}
                            y={(viewBox.cy || 0) + 24}
                          >
                            Veículos
                          </tspan>
                        </text>
                      )
                    }
                  }}
                />
              </Pie>
            </PieChart>
          </ChartContainer>
        </CardContent>
      </div>
      <CardFooter className="flex-col gap-2 text-sm">
        <a
          className="flex items-center gap-2 font-medium leading-none hover:underline"
          href="/veiculos"
        >
          ver detalhes
        </a>
      </CardFooter>
    </Card>
  ) : (
    <SingleValueCard
      title="Veículos cadastrados"
      description={description}
      value={0}
      unit="Veículos"
      link={{ href: '/veiculos', text: 'ver detalhes' }}
    />
  );
};
