import { useQuery } from 'react-query';

import { http } from '../../useAxios';
import { FilterAlertType } from '../../../models/FilterAlertType';

export async function getAlertsByYesterday(filter: string): Promise<FilterAlertType[]> {
  try {
    const { data: vehiclesByYesterday } = await http.get<FilterAlertType[]>(
      `/Videos/ListarAlertasCategorizadosOntem?filter=${filter}`,
    );

    return vehiclesByYesterday;
  } catch (error) {
    console.log(error);

    return [];
  }
};

export function useFilterAlertsByYesterday(filter?: string, shouldFetch: boolean = true) {
  const ft = filter || 'all';

  return useQuery({
    queryKey: [`alerts-by-yesterday-${ft}`],
    queryFn: () => getAlertsByYesterday(ft),
    staleTime: 1000 * 60 * 60,
    enabled: shouldFetch,
  });
};
