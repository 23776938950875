import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import VideosPlayer from "@/components/VideosPlayer";

import { useLoaderContext } from "@/contexts/LoaderContext";
import { getGenerateLinks, getListVideos } from "@/services/hooks/useVideos";
import { TVehiclesCamerasByDate } from "@/models/TVehiclesCamerasByDate";
import { VideoInputOutputDTO } from "@/models/VideoInputOutputDTO";
import { cn } from "@/lib/utils";
import { VideoSourceType } from "@/models/VideoSourceType";
import Playlist from "@/components/Playlist";
import VideoPageHeader from "@/components/VideoPageHeader";
// import LiveMap from "@/components/LiveMap";

export default function Video() {
  const navigate = useNavigate();

  const { startDate = '', veiculoId = '', startHour = '', videoTime = '0' } = useParams();

  const { handleStartLoader } = useLoaderContext();

  const [loaded, setLoaded] = useState<boolean>(false);
  const [allVehicleVideoDates, setAllVehicleVideoDates] = useState<string[]>([]);
  const [allVehicleVideos, setAllVehicleVideos] = useState<TVehiclesCamerasByDate[]>([]);
  const [selectedVehicleVideo, setSelectedVehicleVideo] = useState<VideoInputOutputDTO>();
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [shouldRemount, setShouldRemount] = useState<boolean>(false);
  const [videoFullscreen, setVideoFullscreen] = useState<boolean>(false);
  const [mosaicFullscreen, setMosaicFullscreen] = useState<boolean>(false);
  const [visibleControl, setVisibleControl] = useState<boolean>(true);
  const [showPlaylist, setShowPlaylist] = useState<boolean>(false);
  const [videosSources, setVideosSources] = useState<VideoSourceType[]>([]);

  useEffect(() => {
    if (!loaded) {
      handleStartLoader()
    }
  }, [handleStartLoader, loaded]);

  const getRecentVehicleVideos = useCallback(
    async () => {
      if (!veiculoId.length) return;

      const today = new Date();
      const initial = new Date(today);

      initial.setDate(today.getDate() - 59);

      const formattedToday = today.toISOString().split('T')[0];
      const formattedInitial = initial.toISOString().split('T')[0];

      try {
        const vehicleCamera = await getListVideos(
          veiculoId,
          formattedInitial,
          formattedToday,
        );

        setLoaded(true);
        return vehicleCamera;
      } catch (error) {
        console.error(`Error fetching data for ${formattedInitial} - ${formattedToday}:`, error);
      }
    },
    [veiculoId]
  );

  const getSelectedVideoData = useCallback(
    async (selected: VideoInputOutputDTO | null) => {
      if (!selected) return null;

      try {
        const videoLinks = await getGenerateLinks(selected);

        return videoLinks;
      } catch (error) {
        console.error(`Error fetching data for: `, error);
      }

      return null;
    },
    [],
  );

  useEffect(() => {
    if (loaded || !startDate || !veiculoId) return;
  
    const getVideos = async () => {
      const vehicleVideosData = await getRecentVehicleVideos();

      if (!!vehicleVideosData) {
        const videoDays = vehicleVideosData.reduce(
          (accumulator: string[] = [], _video: VideoInputOutputDTO) => (
            !accumulator.includes(_video.dataDoVideo)
              ? [...accumulator, _video.dataDoVideo]
              : accumulator
          ),
          [],
        );

        if (!!videoDays?.length) {
          setAllVehicleVideoDates(videoDays);

          const allVideos = videoDays.map((_date) => ({
            date: _date,
            vehicles: vehicleVideosData.filter(
              (_video) => _video.dataDoVideo === _date,
            ),
          }));

          setAllVehicleVideos(allVideos);
        }
      }
    };
  
    getVideos();
  }, [getRecentVehicleVideos, loaded, startDate, veiculoId]);

  const startDateVehicleVideos = useMemo(() => (
    allVehicleVideos.find((_video) => _video.date === startDate)
  ), [allVehicleVideos, startDate]);

  useEffect(() => {
    const selectedVehicleVideoData = !startHour?.length
      ? startDateVehicleVideos?.vehicles[0]
      : startDateVehicleVideos?.vehicles?.find(
        (_video) => (_video.horaDoVideo === startHour.replace('-', ':')),
      );

    if (!selectedVehicleVideoData) return;

    const fetchData = async () => {
      const videoData = await getSelectedVideoData(selectedVehicleVideoData);

      if (!!videoData) {
        setSelectedVehicleVideo(videoData);
      }

    };

    fetchData();
  }, [getSelectedVideoData, startDateVehicleVideos?.vehicles, startHour]);

  const selectedVideoIndex = useMemo(() => {
    if (!startDateVehicleVideos?.vehicles?.length || !selectedVehicleVideo?.id) {
      return 1
    };

    return startDateVehicleVideos.vehicles
      .findIndex((_video) => _video.id === selectedVehicleVideo.id) + 1;
  }, [startDateVehicleVideos, selectedVehicleVideo]);

  const handleClickPlaybackRate = () => {
    const allowed = [1, 2, 5, 10, 15];

    setPlaybackRate((previous) => (
      allowed[(allowed.indexOf(previous) + 1) % allowed.length]
    ));
  };

  const handleChangeDate = (date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];

    if (!!veiculoId?.length && !!formattedDate.length) {
      navigate(`/veiculos/${veiculoId}/${formattedDate}/videos`);
    }
  };

  const onVideoNavigation = (index: number) => {
    navigate(`/veiculos/${
      veiculoId
    }/${
      startDate
    }/videos/${
      startDateVehicleVideos?.vehicles[index].horaDoVideo.replace(':', '-')
    }`);
  };

  const handleClickNextVideo = () => {
    if (!selectedVehicleVideo || !startDateVehicleVideos?.vehicles) return;

    const index = startDateVehicleVideos?.vehicles?.findIndex(
      (_vehicle) => _vehicle.id === selectedVehicleVideo?.id
    );

    if (index + 1 < startDateVehicleVideos?.vehicles?.length) {
      onVideoNavigation(index + 1)
    }
  };

  const handleClickPreviousVideo = () => {
    if (!selectedVehicleVideo || !startDateVehicleVideos?.vehicles) return;

    const index = startDateVehicleVideos?.vehicles?.findIndex(
      (_vehicle) => _vehicle.id === selectedVehicleVideo?.id
    );

    if (index - 1 >= 0) {
      onVideoNavigation(index - 1)
    }
  };

  // const memoizedLiveMap = useMemo(() => {
  //   return (
  //     <div className="h-full pt-1.5 hidden lg:block lg:pl-6 lg:pr-12">
  //       <LiveMap
  //         coordinates={[-60.007630000000006, -3.029171000000005]}
  //         direction={87}
  //       />
  //     </div>
  //   );
  // }, []);

  return !!selectedVehicleVideo && !!startDateVehicleVideos?.vehicles ? (
    <>
      <div
        key={shouldRemount ? 'remount' : 'original'}
        className="w-full h-screen bg-black flex overflow-y-hidden"
      >
        <div className={cn(
          'flex flex-col lg:transition-all lg:ease-linear py-4 px-6 lg:px-12',
          showPlaylist && ([
            'flex-grow w-0 overflow-x-hidden overflow-y-auto',
            'lg:pr-0 lg:overflow-y-hidden lg:w-[calc(100%-320px)] lg:duration-500',
          ]),
          !showPlaylist && 'w-full lg:duration-300',
        )}>
          <div className={cn(showPlaylist && 'pr-12')}>
            <VideoPageHeader
              veiculoId={veiculoId}
              selectedVideo={selectedVehicleVideo}
              activeVideo={selectedVideoIndex}
              videosList={startDateVehicleVideos.vehicles}
              allVideosDates={allVehicleVideoDates}
              showPlaylist={showPlaylist}
              mosaicFullscreen={mosaicFullscreen}
              handleChangeMosaicFullscreen={() => {
                setMosaicFullscreen((previous) => !previous)
              }}
              handleClickPrevious={handleClickPreviousVideo}
              handleClickNext={handleClickNextVideo}
              handleChangeDate={handleChangeDate}
              handleTogglePlaylist={() =>setShowPlaylist((previous) => !previous)}
            />
          </div>
          <div className={cn(
            'w-full h-full flex items-center',
            showPlaylist && 'pr-12'
          )}>
            <VideosPlayer
              key={`${startDate}-${veiculoId}-${startHour}`}
              playbackRate={playbackRate}
              selectedVideo={selectedVehicleVideo}
              selectedVideoIndex={selectedVideoIndex}
              shouldRemount={shouldRemount}
              showPlaylist={showPlaylist}
              veiculoId={veiculoId}
              videoFullscreen={videoFullscreen}
              videoStart={(parseInt(videoTime))}
              videosList={startDateVehicleVideos?.vehicles}
              videosSources={videosSources}
              visibleControl={visibleControl}
              mosaicFullscreen={mosaicFullscreen}
              handleClickPlaybackRate={handleClickPlaybackRate}
              handleSetShouldRemount={setShouldRemount}
              handleSetVideoFullscreen={setVideoFullscreen}
              handleSetVideosSources={setVideosSources}
              handleSetVisibleControl={setVisibleControl}
            />
          </div>
        </div>
        <div className={cn(
          'fixed w-full h-fit z-20 -bottom-[calc(100%+4px)] transition-all',
          'lg:relative lg:bottom-0 lg:h-full lg:overflow-hidden',
          showPlaylist && 'bottom-0 duration-500 ease-out lg:w-[320px]',
          !showPlaylist && 'duration-300 ease-linear lg:w-0',
        )}>
          <div className="w-full lg:w-[320px]">
            <Playlist
              videos={startDateVehicleVideos?.vehicles}
              videoIndex={selectedVideoIndex}
              handleClosePlaylist={() => setShowPlaylist(false)}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};
