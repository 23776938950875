import { useEffect, useMemo, useState } from "react";
import { Search } from "lucide-react";

import FleetTabContent from "@/components/FleetTabContent";
import NavBar from "@/components/NavBar";
import VideosDatePicker from "@/components//FilterPeriodDatePicker";
import { Input } from "@/components/ui/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";

import { useFilterContext } from "@/contexts/FilterContext";
import { useLoaderContext } from "@/contexts/LoaderContext";
import { getAllLiveFleet } from "@/services/lives";
import {
  useFilterVehiclesByLastMonth,
  useFilterVehiclesByLastWeek,
  useFilterVehiclesByRange,
  useFilterVehiclesByToday,
  useFilterVehiclesByYesterday,
} from "@/services/hooks/useFilterVehicles";
import { useListBases } from "@/services/hooks/useListBases";

import { OnlineVehicleType } from "@/models/OnlineVehicleType";

export default function Fleet() {
  const {
    base,
    filterDateRange,
    filterPeriod,
    setDisplayDateRange,
    setFilterPeriod,
  } = useFilterContext();
  const { handleStartLoader, handleStopLoader } = useLoaderContext();

  const [vehicleFilter, setVehicleFilter] = useState<string>('');
  const [onlineVehicles, setOnlineVehicles] = useState<OnlineVehicleType[]>([]);

  const { data: bases = [], isLoading: isLoadingBases } = useListBases();

  const {
    data: vehiclesByLastWeek = [],
    isLoading: isLoadingLastWeek,
  } = useFilterVehiclesByLastWeek(base);

  const canFetchOtherData = !isLoadingBases && !isLoadingLastWeek;

  const today = new Date();
  const yesterday = new Date();
  const lastWeek = new Date();
  const lastMonth = new Date();

  yesterday.setDate(today.getDate() - 1);
  lastWeek.setDate(today.getDate() - 7);
  lastMonth.setDate(today.getDate() - 30);

  const {
    data: vehiclesByToday = [],
  } = useFilterVehiclesByToday(base, canFetchOtherData);
  
  const {
    data: vehiclesByYesterday = [],
  } = useFilterVehiclesByYesterday(base, canFetchOtherData);

  const {
    data: vehiclesByLastMonth = [],
  } = useFilterVehiclesByLastMonth(base, canFetchOtherData);

  const { data: vehiclesByRange = [] } = useFilterVehiclesByRange(
    !!filterDateRange?.from
      ? [filterDateRange.from, filterDateRange.to || filterDateRange.from || today]
      : [today, today],
    base,
    canFetchOtherData,
  );

  useEffect(() => {
    if (!handleStartLoader || !handleStopLoader) {
      return;
    }

    if (isLoadingLastWeek || isLoadingBases) {
      handleStartLoader();
    } else {
      handleStopLoader();
    }
  }, [
    isLoadingBases,
    isLoadingLastWeek,
    handleStartLoader,
    handleStopLoader,
  ]);

  useEffect(() => {
    const fetchFleetData = async () => {
      const data = await getAllLiveFleet();

      setOnlineVehicles(data);
    };

    if (process.env.REACT_APP_VERIFY_LIVE_VIDEOS === 'true') {
      fetchFleetData();

      const interval = setInterval(fetchFleetData, 1000 * 30);

      return () => clearInterval(interval);
    };
  }, []);

  const filteredVehiclesByToday = useMemo(() => {
    let current = vehiclesByToday;

    if (!!vehicleFilter?.length) {
      current = current.filter(
        (vehicle) => (
          vehicle.nome.toLocaleLowerCase().includes(
            vehicleFilter.toLocaleLowerCase(),
          )
        ),
      );
    }

    return current;
  }, [vehicleFilter, vehiclesByToday]);

  const filteredVehiclesByYesterday = useMemo(() => {
    let current = vehiclesByYesterday;

    if (!!vehicleFilter?.length) {
      current = current.filter(
        (vehicle) => (
          vehicle.nome.toLocaleLowerCase().includes(
            vehicleFilter.toLocaleLowerCase(),
          )
        ),
      );
    }

    return current;
  }, [vehicleFilter, vehiclesByYesterday]);

  const filteredVehiclesByLastWeek = useMemo(() => {
    let current = vehiclesByLastWeek;

    if (!!vehicleFilter?.length) {
      current = current.filter(
        (vehicle) => (
          vehicle.nome.toLocaleLowerCase().includes(
            vehicleFilter.toLocaleLowerCase(),
          )
        ),
      );
    }

    return current;
  }, [vehicleFilter, vehiclesByLastWeek]);

  const filteredVehiclesByLastMonth = useMemo(() => {
    let current = vehiclesByLastMonth;

    if (!!vehicleFilter?.length) {
      current = current.filter(
        (vehicle) => (
          vehicle.nome.toLocaleLowerCase().includes(
            vehicleFilter.toLocaleLowerCase(),
          )
        ),
      );
    }

    return current;
  }, [vehicleFilter, vehiclesByLastMonth]);

  const filteredVehiclesByRange = useMemo(() => {
    let current = vehiclesByRange;

    if (!!vehicleFilter?.length) {
      current = current.filter(
        (vehicle) => (
          vehicle.nome.toLocaleLowerCase().includes(
            vehicleFilter.toLocaleLowerCase(),
          )
        ),
      );
    }

    return current;
  }, [vehicleFilter, vehiclesByRange]);

  const onClickTabTrigger = (period: string) => {
    const today = new Date();

    switch (period) {
      case 'today':
        setDisplayDateRange({ from: today })
        setFilterPeriod('today')
        return;

      case 'yesterday':
        const yesterday = new Date(today);

        yesterday.setDate(today.getDate() - 1);
        setDisplayDateRange({ from: yesterday })
        setFilterPeriod('yesterday')
        return;

      case 'lastWeek':
        const lastWeek = new Date(today);

        lastWeek.setDate(today.getDate() - 7);
        setDisplayDateRange({ from: lastWeek, to: today })
        setFilterPeriod('lastWeek')
        return;

      case 'lastMonth':
        const lastMonth = new Date(today);

        lastMonth.setDate(today.getDate() - 30);
        setDisplayDateRange({ from: lastMonth, to: today })
        setFilterPeriod('lastMonth')
        return;
    }
  }

  return (
    <>
      <div className="w-full max-w-[1440px] mx-auto">
        <NavBar bases={bases} />
        <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8">
          <Tabs className="flex-1 h-full" value={filterPeriod}>
            <div className="flex items-center">
              <div className="w-fit flex items-center gap-3">
                <TabsList>
                  <TabsTrigger
                    value="today"
                    onClick={() => onClickTabTrigger('today')}
                  >
                    Hoje
                  </TabsTrigger>
                  <TabsTrigger
                    value="yesterday"
                    onClick={() => onClickTabTrigger('yesterday')}
                  >
                    Ontem
                  </TabsTrigger>
                  <TabsTrigger
                    value="lastWeek"
                    onClick={() => onClickTabTrigger('lastWeek')}
                  >
                    Última semana
                  </TabsTrigger>
                  <TabsTrigger
                    className="hidden sm:flex"
                    value="lastMonth"
                    onClick={() => onClickTabTrigger('lastMonth')}
                  >
                    Último mês
                  </TabsTrigger>
                </TabsList>
                <VideosDatePicker />
              </div>
              <div className="ml-auto flex items-center gap-2">
                <div className="relative ml-auto flex-1 md:grow-0">
                  <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                  <Input
                    type="search"
                    className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
                    placeholder="Digite a placa..."
                    onChange={(event) => setVehicleFilter(event.target.value)}
                  />
                </div>
              </div>
            </div>
            <FleetTabContent
              period="today"
              vehicles={filteredVehiclesByToday}
              onlineVehicles={onlineVehicles}
            />
            <FleetTabContent
              period="yesterday"
              vehicles={filteredVehiclesByYesterday}
              onlineVehicles={onlineVehicles}
            />
            <FleetTabContent
              period="lastWeek"
              vehicles={filteredVehiclesByLastWeek}
              onlineVehicles={onlineVehicles}
            />
            <FleetTabContent
              period="lastMonth"
              vehicles={filteredVehiclesByLastMonth}
              onlineVehicles={onlineVehicles}
            />
            <FleetTabContent
              period="range"
              vehicles={filteredVehiclesByRange}
              onlineVehicles={onlineVehicles}
            />
          </Tabs>
        </main>
      </div>
    </>
  );
};
