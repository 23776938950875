import { useEffect, useMemo, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Maximize2Icon,
  Minimize2Icon,
  SignalHighIcon,
  XCircleIcon,
} from "lucide-react";

import LiveMap from "@/components/LiveMap";
import LiveMosaic from "@/components/LiveMosaic";

import { useFilterContext } from "@/contexts/FilterContext";
import { useLoaderContext } from "@/contexts/LoaderContext";
import { getAllLiveFleet } from "@/services/lives";
import { useFilterVehiclesByToday } from "@/services/hooks/useFilterVehicles";
import { useToast } from "@/hooks/use-toast";

import { cn } from "@/lib/utils";

export default function LiveVehicle() {
  const { veiculoId = '', deviceSerial = '' } = useParams();

  const navigate = useNavigate();

  const { toast } = useToast();

  const { base } = useFilterContext();
  const { handleStartLoader, handleStopLoader } = useLoaderContext();

  const [lastDirection, setLastDirection] = useState<number>(0);
  const [lastPosition, setLastPosition] = useState<[number, number]>([0, 0]);
  const [videoFullscreen, setVideoFullscreen] = useState<boolean>(false);
  const [visibleControl, setVisibleControl] = useState<boolean>(true);
  const [networkRssi, setNetworkRssi] = useState<0 | 1 | 2 | 3>(0);
  const [mosaicOnly, setMosaicOnly] = useState<boolean>(false);

  const {
    data: vehiclesByToday = [],
    isLoading: isLoadingVehiclesByToday,
  } = useFilterVehiclesByToday(base);

  const vehicleData = useMemo(() => {
    const data = vehiclesByToday.find((v) => v.veiculoId === veiculoId);

    return data ?? null;
  }, [vehiclesByToday, veiculoId]);

  useEffect(() => handleStopLoader(), [handleStopLoader]);

  useEffect(() => {
    if (!handleStartLoader || !handleStopLoader) return;

    if (isLoadingVehiclesByToday) {
      handleStartLoader();
    } else {
      const timeout = setTimeout(() => handleStopLoader(), 500);
      return () => clearTimeout(timeout);
    }
  }, [isLoadingVehiclesByToday, handleStartLoader, handleStopLoader]);

  useEffect(() => {
    if (process.env.REACT_APP_VERIFY_LIVE_VIDEOS !== 'true') {
      navigate('/veiculos');

      return;
    }

    if (!deviceSerial) return;

    const fetchFleetData = async () => {
      const data = await getAllLiveFleet();
      const vehicleData = data?.find((d) => d?.deviceSerial === deviceSerial);
      
      if (!vehicleData) {
        toast({
          variant: 'destructive',
          description: "O veículo está offline no momento.",
        });
      } else {
        if (!!vehicleData.position?.coordinates) {
          setLastPosition(vehicleData.position.coordinates);
        }

        if (!!vehicleData.direction) {
          setLastDirection(vehicleData.direction);
        }

        if ([0, 1, 2, 3].includes(vehicleData?.networkRssi)) {
          setNetworkRssi(vehicleData?.networkRssi);
        }
      }
    };

    fetchFleetData();

    const interval = setInterval(fetchFleetData, 1000 * 30);

    return () => clearInterval(interval);
  }, [deviceSerial, toast, navigate]);

  const veiculoNome = useMemo(() => (
    !!vehicleData?.nome?.length ? vehicleData.nome : ''
  ), [vehicleData]);

  const veiculoLocalidade = useMemo(() => (
    !!vehicleData?.localidade?.length ? vehicleData.localidade : ''
  ), [vehicleData]);

  return (
    <>
      <div className="w-full h-screen bg-black text-white overflow-hidden">
        <div className={cn(
          'w-full max-w-[1920px] mx-auto min-h-screen z-10',
          'flex justify-center items-center',
        )}>
          {videoFullscreen && !visibleControl ? (
            <div className={cn(
              'w-full h-screen fixed top-0 left-0 z-30',
              'bg-transparent cursor-none',
            )} />
          ) : null}
          {!mosaicOnly ? (
            <Link
              className={cn(
                'w-fit fixed top-6 right-6',
                'text-neutral-500 hover:text-white transition-colors',
              )}
              to={`/veiculos/${veiculoId}`}
            >
              <XCircleIcon size={32} />
            </Link>
          ) : null}
          <button
            className={cn(
              'w-fit fixed top-6 right-20 hidden transition-colors',
              'text-neutral-500 hover:text-white',
              'lg:block',
              mosaicOnly && 'right-6',
            )}
            onClick={() => setMosaicOnly((previous) => !previous)}
          >
            {mosaicOnly ? (
              <Minimize2Icon size={32} />
            ) : (
              <Maximize2Icon size={32} />
            )}
          </button>
          <div className={cn(
            'w-full min-h-screen py-24 grid-cols-1 grid-rows-[64px_auto] grid',
            'lg:pt-4 lg:pb-16 lg:transition-all lg:ease-linear lg:duration-300',
          )}>
            <div className={cn(
              'w-full space-y-1.5 mt-2 order-2',
              'lg:px-12 lg:mb-16 lg:grid lg:grid-cols-3 lg:order-2',
              mosaicOnly && 'lg:mb-0',
            )}>
              <div className={cn(
                'absolute top-6 left-6 text-base',
                'lg:relative lg:top-auto lg:left-auto',
                mosaicOnly && 'hidden',
              )}>
                {!!veiculoNome?.length ? (
                  <p className="flex gap-x-2.5 whitespace-nowrap">
                    VEÍCULO: {veiculoNome}
                    {' '}
                    <SignalHighIcon className={cn(
                      'w-7 h-7 inline-block -translate-y-1',
                      networkRssi === 0 && 'signal-none',
                      networkRssi === 1 && 'signal-low',
                      networkRssi === 2 && 'signal-medium',
                      networkRssi === 3 && 'signal-high',
                    )}
                    />
                  </p>
                ) : null}
                {!!veiculoLocalidade?.length ? (
                  <p>BASE: {veiculoLocalidade}</p>
                ) : null}
              </div>
            </div>
            <div className={cn(
              'min-h-64 lg:h-full lg:grid lg:grid-cols-5 lg:gap-8 lg:order-3',
              mosaicOnly && [
                'h-auto w-auto pr-12 max-w-full max-h-[90vh] aspect-[4.5/3] mx-auto',
                'lg:block',
              ],
            )}>
              <div className={cn(
                'lg:flex lg:items-center',
                mosaicOnly ? 'lg:col-span-4 lg:col-start-2' : 'lg:col-span-3',
                mosaicOnly && 'mx-auto -translate-y-10'
              )}>
                <LiveMosaic
                  deviceSerial={deviceSerial}
                  videoFullscreen={videoFullscreen}
                  visibleControl={visibleControl}
                  handleSetVideoFullscreen={setVideoFullscreen}
                  handleSetVisibleControl={setVisibleControl}
                />
              </div>
              <div className={cn(
                'h-full lg:col-span-2',
                mosaicOnly && 'hidden',
              )}>
                <div className={cn(
                  'w-full h-full px-8',
                  'lg:px-0 lg:pr-12',
                )}>
                  <LiveMap coordinates={lastPosition} direction={lastDirection} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
