import { VideoOffIcon } from "lucide-react";

import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";

import { cn, formatVideoDuration } from "@/lib/utils";

import "react-datepicker/dist/react-datepicker.css";

interface VideoTimelapseControlProps {
  activeVideo: number;
  duration: number;
  played: number;
  timeLapse?: string[];
  videoFullscreen: boolean;
  handleSliderSeek: (value: number) => void;
};

const slices = 15;

export default function VideoTimelapseControl({
  activeVideo,
  duration,
  played,
  timeLapse,
  videoFullscreen,
  handleSliderSeek,
}: VideoTimelapseControlProps) {
  const handleClickThumbnail = (piece: number, max: number) => {
    const value = piece / (max || 1);

    handleSliderSeek(value);
  };

  return (
    <ScrollArea className="lg:w-full">
      <div
        className={cn(
          'h-fit pt-4 pb-1 xl:pt-4 flex',
          'lg:grid lg:grid-cols-15 lg:min-w-100vw lg:px-1',
          videoFullscreen && ([
            'justify-center',
            'w-full fixed bottom-6 left-1/2 z-40 -translate-x-1/2',
            'bg-black/50',
          ])
        )}
      >
        {Array.from({ length: slices }, (_, i) => i).map((slice) => {
          const activated = played >= (1 / slices) * slice
            && played < (1 / slices) * (slice + 1);
          const image = timeLapse?.[slice];

          return (
            <button
              key={slice}
              className={cn(
                'w-24 h-16 block flex-none relative text-2xs',
                'ring-2 ring-offset-2 ring-transparent ring-offset-transparent',
                'bg-neutral-600 transition-all',
                'lg:w-full lg:h-12 xl:h-16',
                activated && 'z-10 ring-white ring-offset-black',
              )}
              onClick={() => handleClickThumbnail(slice, 15)}
            >
              {!!image ? (
                <img
                  className="block w-full object-cover"
                  src={image}
                  alt={`Imagem ${slice} do vídeo`}
                />
              ) : (
                <div className="bg-neutral-600 max-h-[128px]">
                  <span className="absolute top-0.5 left-1.5">
                    {`CAM ${activeVideo + 1}`}
                  </span>
                  <VideoOffIcon
                    className={cn(
                      'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
                      'stroke-neutral-500',
                    )}
                    size={16}
                  />
                </div>
              )}
              <span
                className={cn(
                  'absolute bottom-0.5 right-1.5',
                  !!image && 'text-shadow-lg shadow-black',
              )}>
                {formatVideoDuration(duration * (slice / slices))}
              </span>
            </button>
          );
        })}
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};
